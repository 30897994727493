import myContacts from "@src/api/contacts/myContacts";
import { useInfiniteQueryOnScroll } from "@src/hooks/useInfiniteQueryOnScroll";
import ContactItem from "@src/pages/contacts/ContactItem";
import { UserType } from "@src/types/UserType";

type Props = {
  searchQuery?: string;
};

const ContactMyContactsContainer = ({ searchQuery }: Props) => {
  const { result: myContactsResult } = useInfiniteQueryOnScroll({
    queryKey: ["contacts", "my-contacts", searchQuery],
    queryFn: ({ pageParam }) => myContacts({ params: { page: pageParam, q: searchQuery } }),
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      const nextPage = lastPage.current_page + 1;
      return nextPage <= lastPage.total_pages ? nextPage : null;
    },
  });

  return (
    <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
      {myContactsResult.data?.pages.map(page =>
        page.data.map((item: UserType) => (
          <ContactItem key={item.id} mode="my-contacts" user={item} />
        )),
      )}
    </div>
  );
};

export default ContactMyContactsContainer;
