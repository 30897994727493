import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment, ReactNode } from "react";

type Props = {
  title?: string;
  superTitle?: ReactNode;
  children: ReactNode;
  padding?: boolean;
  onClose?: () => void;
  windowStyle?: string;
  mobileFullscreen?: boolean;
};

const PopupWrapper = ({
  title,
  superTitle,
  children,
  padding = true,
  onClose,
  windowStyle,
  mobileFullscreen,
}: Props) => {
  return (
    <Fragment>
      <div className="fixed inset-0 z-40 bg-black/40" onClick={onClose} aria-hidden />
      <div
        className={`${windowStyle} fixed inset-0 left-1/2 top-1/2 z-50 h-fit max-h-full min-h-[200px] w-[90vw] min-w-[300px] -translate-x-1/2 -translate-y-1/2 rounded-2xl border border-gray-300 bg-white shadow-2xl md:w-fit ${
          mobileFullscreen &&
          "max-lg:left-0 max-lg:top-0 max-lg:size-full max-lg:translate-x-0 max-lg:translate-y-0 max-lg:rounded-none"
        }`}
      >
        <div className={`${padding && "p-3"} relative`}>
          {superTitle && (
            <div className="absolute left-1/2 top-[-28px] -translate-x-1/2">{superTitle}</div>
          )}
          {title && (
            <div className="mb-4 flex w-full items-center gap-2 border-b pb-1 text-base font-semibold">
              {title}
            </div>
          )}
          {!title && <div className="mb-7" />}
          <button onClick={onClose} className="absolute right-3 top-3 text-black">
            <XMarkIcon className="size-6" />
          </button>
          {children}
        </div>
      </div>
    </Fragment>
  );
};

export default PopupWrapper;
