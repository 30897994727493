import { useState } from "react";
import { useTranslation } from "react-i18next";

import Input from "@src/components/elements/input/Input";
import SoundsThumbnailList from "@src/components/elements/posts/sounds/SoundsThumbnailList";
import WatchThumbnailList from "@src/components/elements/posts/watch/WatchThumbnailList";
import TabBodies from "@src/components/elements/shared/tab/TabBodies";
import TabBody from "@src/components/elements/shared/tab/TabBody";
import TabController from "@src/components/elements/shared/tab/TabController";
import TabHeader from "@src/components/elements/shared/tab/TabHeader";
import TabHeaders from "@src/components/elements/shared/tab/TabHeaders";
import Tab from "@src/components/menu/Tab";
import BackButton from "@src/components/nav/BackButton";
import News from "@src/pages/bookmarks/tabcontent/News";
import { useAppSelector } from "@src/state/hooks";

type Props = {
  tab: string;
};

const BookmarksContainer = ({ tab }: Props) => {
  const [searchQuery, setSearchQuery] = useState("");
  const { t } = useTranslation();

  const { user } = useAppSelector(state => state.user);
  if (!user) return null;

  return (
    <>
      <BackButton stepback />
      <TabController defaultTab={tab}>
        <TabHeaders>
          <TabHeader id="feed" url="/bookmarks/feed">
            {t("main.header.links.feed")}
          </TabHeader>
          <TabHeader id="watch" url="/bookmarks/watch">
            {t("main.header.links.watch")}
          </TabHeader>
          <TabHeader id="sounds" url="/bookmarks/sounds">
            {t("main.header.links.sounds")}
          </TabHeader>
          <TabHeader id="jobs" url="/bookmarks/jobs">
            {t("main.header.links.jobs")}
          </TabHeader>
        </TabHeaders>

        <Input
          name="search-all"
          placeholder={t("components.shared.search", {
            tab: t("main.header.links." + tab),
          })}
          onChange={e => setSearchQuery(e)}
          clearable
        />

        <TabBodies>
          <TabBody id="watch">
            <WatchThumbnailList bookmarked={true} />
          </TabBody>
          <TabBody id="sounds">
            <SoundsThumbnailList search={searchQuery} small fineGrid />
          </TabBody>
          <TabBody id="feed">
            <News />
          </TabBody>
          <TabBody id="jobs">
            <Tab isActive={tab === "jobs"}>
              <div className="grid grid-cols-2 gap-1 md:grid-cols-3 xl:grid-cols-4 xl:gap-3 2xl:grid-cols-5"></div>
            </Tab>
          </TabBody>
        </TabBodies>
      </TabController>
    </>
  );
};

export default BookmarksContainer;
