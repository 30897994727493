import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import blockUserModalReducer from "@src/state/blockUserModal/reducer";
import autoScrollReducer from "@src/state/modal/autoScrollSlice";
import modalSliceReducer from "@src/state/modal/modalSlice";
import paymentModalReducer from "@src/state/modal/paymentModalSlice";
import popupSliceReducer from "@src/state/modal/popupSlice";
import searchReducer from "@src/state/modal/searchSlice";
import userBubbleReducer from "@src/state/modal/userBubbleSlice";
import notificationsReducer from "@src/state/notifications/notificationsSlice";
import avatarSliceReducer from "@src/state/profileImages/avatarSlice";
import headerImageSliceReducer from "@src/state/profileImages/headerImageSlice";
import imageEditorSliceReducer from "@src/state/profileImages/imageEditorSlice";
import reportModalReducer from "@src/state/reportModal/reducer";
import userReducer from "@src/state/user/userSlice";

const userPersisted = persistReducer(
  {
    key: "user",
    version: 2,
    storage,
    whitelist: ["user", "token", "refreshToken"],
  },
  userReducer,
);

const rootReducer = combineReducers({
  user: userPersisted,
  notifications: notificationsReducer,
  userBubble: userBubbleReducer,
  autoScroll: autoScrollReducer,
  search: searchReducer,
  login: modalSliceReducer,
  popups: popupSliceReducer,
  reportModal: reportModalReducer,
  blockUserModal: blockUserModalReducer,
  paymentModal: paymentModalReducer,
  avatar: avatarSliceReducer,
  headerImage: headerImageSliceReducer,
  // Only for s3 CORS workaround
  imageEditor: imageEditorSliceReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 256 },
      serializableCheck: {
        warnAfter: 256,
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
