import { ReactNode, RefObject } from "react";
import ReactPlayer from "react-player";
import { NavLink } from "react-router-dom";

type Props = {
  to: string;
  className: string;
  playerRef: RefObject<ReactPlayer>;
  onPause?: () => void;
  onResume?: () => void;
  onPlay?: () => void;
  onFailure?: () => void;
  children: ReactNode;
};

const ReactPlayerNavLink = ({
  to,
  className,
  playerRef,
  onPause,
  onResume,
  onPlay,
  onFailure,
  children,
}: Props) => {
  const getVideoPlayer = (ref: RefObject<ReactPlayer>) => {
    if (!ref.current) {
      return null;
    }

    return ref.current.getInternalPlayer() as HTMLVideoElement | null;
  };

  const playVideo = (player: HTMLVideoElement) => {
    player
      .play()
      .then(onPlay)
      .catch(error => {
        if (onFailure) {
          onFailure();
        }

        if (error.name !== "AbortError") {
          throw error;
        }
      });
  };

  return (
    <NavLink
      to={to}
      className={className}
      onMouseEnter={() => {
        const player = getVideoPlayer(playerRef);

        if (!player) {
          return;
        }

        playVideo(player);
      }}
      onMouseLeave={() => {
        if (!playerRef.current) {
          return null;
        }

        playerRef.current.seekTo(0);

        if (onResume) {
          onResume();
        }
      }}
      onClick={() => {
        const player = getVideoPlayer(playerRef);

        if (!player) {
          return;
        }

        if (!player.paused) {
          player.pause();

          if (onPause) {
            onPause();
          }
          return;
        }

        playVideo(player);
      }}
    >
      {children}
    </NavLink>
  );
};

export default ReactPlayerNavLink;
