import "@src/instrument";

import "@src/styles/tailwind.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Hls from "hls.js";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "@src/App";
import GlobalStyle from "@src/GlobalStyle";
import { store } from "@src/state/store";

const queryClient = new QueryClient();
const rootElement = document.getElementById("root");
const root = createRoot(rootElement as Element);

if (window.Hls === undefined) {
  window.Hls = Hls;
}

root.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <GlobalStyle />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  </Provider>,
);
