import {
  PauseIcon,
  PlayIcon,
  SpeakerWaveIcon,
  SpeakerXMarkIcon,
} from "@heroicons/react/24/outline";
import { MusicalNoteIcon } from "@heroicons/react/24/solid";
import { AxiosError } from "axios";
import { RefObject, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { NavLink } from "react-router-dom";

import view from "@src/api/posts/view";
import ProfileAvatar from "@src/components/elements/shared/ProfileAvatar";
import TextView from "@src/components/elements/textedit/TextView";
import CommentsPopup from "@src/components/popup/CommentsPopup";
import SharePopup from "@src/components/popup/SharePopup";
import WatchSidebar from "@src/pages/watch/WatchSidebar";
import { useAppSelector } from "@src/state/hooks";
import useAutoScroll from "@src/state/modal/useAutoScroll";
import useLoginModal from "@src/state/modal/useLoginModal";
import { PostType } from "@src/types/PostType";
import getName from "@src/utils/getName";
import useWatchLike from "@src/utils/useWatchLike";

type Props = {
  data: PostType;
  active: boolean;
  preload: boolean;
  autoplay?: boolean;
  key?: string;
  muted?: boolean;
  setMuted?: (muted: boolean) => void;
  queryKey?: (string | null)[];
  scrollRef?: RefObject<HTMLDivElement>;
};

const Watch = ({
  data,
  active,
  autoplay,
  muted = true,
  setMuted,
  preload,
  queryKey = ["posts", "watch"],
  scrollRef,
}: Props) => {
  const [viewed, setViewed] = useState(false);
  const ref = useRef<ReactPlayer | null>(null);
  const [playing, setPlaying] = useState(autoplay);
  const [preventPause, setPreventPause] = useState(false);
  const [timeRatio, setTimeRatio] = useState(0);
  const { user } = useAppSelector(state => state.user);
  const loginModal = useLoginModal();

  const likeMutation = useWatchLike({ data, queryKey });

  useEffect(() => {
    if (active && !viewed) {
      view({ id: data.id })
        .then(() => setViewed(true))
        .catch(error => {
          if (error instanceof AxiosError && error.response?.status === 404) {
            return;
          }
          throw error;
        });
    }
  }, [active, data.id, viewed]);
  const [sharePopupOpen, setSharePopupOpen] = useState(false);
  const [commentsPopupOpen, setCommentsPopupOpen] = useState(false);
  const [sharePopupMobile, setSharePopupMobile] = useState(false);
  const [likeEffect, setLikeEffect] = useState(false);
  const autoScroll = useAutoScroll();
  const [isReady, setIsReady] = useState(false);

  const [videoHeight, setVideoHeight] = useState(0);
  const [videoWidth, setVideoWidth] = useState(0);

  const getVideoPlayer = () => {
    if (!ref.current) {
      return null;
    }

    return ref.current.getInternalPlayer() as HTMLVideoElement | null;
  };

  const checkAuth = () => {
    if (!user) {
      loginModal.open();
      return false;
    }
    return true;
  };

  useEffect(() => {
    const player = getVideoPlayer();

    setVideoHeight(player?.videoHeight ?? 0);
    setVideoWidth(player?.videoWidth ?? 0);
  }, [videoHeight, videoWidth]);

  return (
    <div className="group relative snap-start">
      {/* Overlay */}
      <div className="absolute inset-0 cursor-pointer">
        {/* Footer */}
        <div className="pointer-events-none absolute bottom-[70px] left-0 right-[70px] z-10 block md:bottom-[70px] lg:left-3">
          <div className="pointer-events-auto flex flex-row items-center gap-2 overflow-x-hidden rounded-xl py-1 ps-2 text-sm text-white scrollbar-none">
            <div className="pointer-events-auto mb-4 scale-[80%]">
              <ProfileAvatar
                user={{
                  id: data.author.id,
                  name: getName(data.author) ?? "",
                  type: data.author.type,
                  avatar: data.author.avatar_url ?? "",
                }}
                nameStyle="hidden"
              />
            </div>

            <div className="flex w-full flex-col">
              <NavLink
                to={`/profile/${data.author.id}/watch`}
                className="pointer-events-auto w-fit hover:font-semibold"
              >
                @{getName(data.author)}
              </NavLink>
              <div className="line-clamp-2 overflow-x-scroll leading-4 scrollbar-none">
                <TextView value={data.text} hashtagType="watch" />
              </div>
              <div className="flex flex-row">
                <MusicalNoteIcon className="size-5 shrink-0" />
                <div className="w-full overflow-hidden">
                  <div className="animate-marquee w-full"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Sidebar */}
        <WatchSidebar
          isMobile={false}
          checkAuth={checkAuth}
          commentsPopupOpen={commentsPopupOpen}
          data={data}
          likeEffect={likeEffect}
          likeMutation={likeMutation}
          setCommentsPopupOpen={setCommentsPopupOpen}
          setLikeEffect={setLikeEffect}
          setSharePopupMobile={setSharePopupMobile}
          setSharePopupOpen={setSharePopupOpen}
          queryKey={queryKey}
        />
        <div className="absolute bottom-[10px] z-10 mx-[10px] w-[calc(100%-20px)]">
          <input
            type="range"
            min={0}
            max={100}
            value={timeRatio}
            onChange={e => {
              setPreventPause(true);
              setTimeout(() => {
                setPreventPause(false);
              }, 400);
              if (!ref.current) return;
              ref.current.seekTo(
                (ref.current?.getDuration() * parseFloat(e.currentTarget.value)) / 100,
              );
              setTimeRatio(parseInt(e.currentTarget.value));
            }}
            defaultValue={0}
            className="pointer-events-auto h-0.5 w-full cursor-pointer accent-black opacity-0 brightness-[65%] invert duration-200 group-hover:opacity-100"
          />
        </div>
        <div className="absolute bottom-[30px] z-10 flex w-full flex-row-reverse justify-between rounded-xl px-3 pb-2 text-gray-200 lg:px-6 lg:pb-4">
          <button
            className="pointer-events-auto invisible opacity-0 duration-200 group-hover:opacity-100"
            onClick={() => {
              const player = getVideoPlayer();

              if (player === null || !isReady) {
                return;
              }

              if (player.paused) {
                player.play().catch(error => {
                  if (error.name !== "AbortError") {
                    throw error;
                  }
                });
              } else {
                player.pause();
              }
            }}
          >
            {playing ? <PauseIcon className="size-8" /> : <PlayIcon className="size-8" />}
          </button>
          <button
            className="py-0.3 pointer-events-auto rounded-full bg-slate-500/60 px-5 duration-200"
            onClick={e => {
              if (setMuted) setMuted(!muted);
              e.stopPropagation();
            }}
          >
            {muted ? (
              <SpeakerXMarkIcon className="h-7 w-6" />
            ) : (
              <SpeakerWaveIcon className="h-7 w-6" />
            )}
          </button>
        </div>
      </div>
      <div className="h-[calc(100dvh-40px)] text-clip bg-neutral-900 md:h-[calc(100vh-160px)] md:rounded-lg xl:h-[calc(100vh-140px)]">
        <div
          aria-hidden
          className="absolute top-1/2 size-full -translate-y-1/2 cursor-pointer"
          onClick={() => {
            if (!ref.current || preventPause || !isReady) return;
            if (ref.current?.getInternalPlayer().paused) {
              ref.current?.getInternalPlayer().play();
            } else {
              ref.current?.getInternalPlayer().pause();
            }
          }}
        >
          <div
            className={`h-full md:rounded-lg lg:overflow-hidden ${
              videoHeight > videoWidth ? "video-cover" : ""
            }`}
          >
            {preload && (
              <ReactPlayer
                url={`${data.media[0].data_url}/playlist.m3u8`}
                height="100%"
                width="100%"
                controls={false}
                playing={active}
                muted={muted}
                autoplay={true}
                loop={!autoScroll.isEnabled}
                preload={preload}
                responsive
                ref={ref}
                onPlaying={() => setPlaying(true)}
                onPlay={() => setPlaying(true)}
                onPause={() => setPlaying(false)}
                onReady={() => {
                  setIsReady(true);
                  setVideoHeight(ref.current?.getInternalPlayer().videoHeight);
                  setVideoWidth(ref.current?.getInternalPlayer().videoWidth);
                }}
                onEnded={() => {
                  if (autoScroll.isEnabled && scrollRef) {
                    const scrollHeight = scrollRef.current?.offsetHeight ?? 0;
                    scrollRef.current?.scrollBy(0, scrollHeight);
                  }
                }}
                onTimeUpdate={() => {
                  const player = getVideoPlayer();

                  if (!player) {
                    return;
                  }

                  setTimeRatio((player.currentTime / player.duration) * 100);
                }}
              />
            )}
          </div>
        </div>
      </div>
      {sharePopupOpen && (
        <SharePopup
          postId={data.id}
          isMobile={sharePopupMobile}
          content={{
            title: "Watch",
            body: new URL(`/watch/${data.id}`, window.location.href).toString(),
          }}
          onClose={() => setSharePopupOpen(false)}
        />
      )}
      {commentsPopupOpen && (
        <CommentsPopup
          onClose={() => setCommentsPopupOpen(false)}
          comment_count={data.comment_count}
          postId={data.id}
        />
      )}
    </div>
  );
};

export default Watch;
