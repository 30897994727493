import api, { RequestWithParams, requestParams } from "@src/api/api";
import { UserType } from "@src/types/UserType";

type RequestsParams = {
  page?: number;
  q?: string;
};

type RequestsRequest = RequestWithParams<RequestsParams> & {
  type: "sent" | "received";
};

type RequestsResponse = {
  data: UserType[];
  current_page: number;
  total_pages: number;
};

export default async function requests(request: RequestsRequest): Promise<RequestsResponse> {
  const filter = request.type === "sent" ? "requested_by_me" : "requesting_me";
  const { data } = await api.get(
    `/api/v1/contacts${requestParams({ page: 1, ...request.params, filter: filter })}`,
  );
  return data;
}
