import { useTranslation } from "react-i18next";

import TabBodies from "@src/components/elements/shared/tab/TabBodies";
import TabBody from "@src/components/elements/shared/tab/TabBody";
import TabController from "@src/components/elements/shared/tab/TabController";
import TabHeader from "@src/components/elements/shared/tab/TabHeader";
import TabHeaders from "@src/components/elements/shared/tab/TabHeaders";
import BackButton from "@src/components/nav/BackButton";
import { useAppSelector } from "@src/state/hooks";

type Props = {
  tab?: string;
};

const NotificationsContainer = ({ tab = "all" }: Props) => {
  const { user } = useAppSelector(state => state.user);
  const { t } = useTranslation();

  if (!user) {
    return <></>;
  }

  return (
    <>
      <BackButton stepback desktopVisible />
      <TabController defaultTab={tab}>
        <TabHeaders>
          <TabHeader id="all" url="/benachrichtigungen/alle">
            {t("main.interactionNotifications.tabs.all")}
          </TabHeader>
          <TabHeader id="follower" url="/benachrichtigungen/follower">
            {t("main.interactionNotifications.tabs.newFollower")}
          </TabHeader>
          <TabHeader id="friends" url="/benachrichtigungen/freunde">
            {t("main.interactionNotifications.tabs.newFriends")}
          </TabHeader>
          <TabHeader id="answers" url="/benachrichtigungen/antworten">
            {t("main.interactionNotifications.tabs.answers")}
          </TabHeader>
          <TabHeader id="mentions" url="/benachrichtigungen/erwähnungen">
            {t("main.interactionNotifications.tabs.mentions")}
          </TabHeader>
          <TabHeader id="quotes" url="/benachrichtigungen/zitate">
            {t("main.interactionNotifications.tabs.quotes")}
          </TabHeader>
          <TabHeader id="reposts" url="/benachrichtigungen/reposts">
            {t("main.interactionNotifications.tabs.reposts")}
          </TabHeader>
          <TabHeader id="verified" url="/benachrichtigungen/verifiziert">
            {t("main.interactionNotifications.tabs.verified")}
          </TabHeader>
          <TabHeader id="articles" url="/benachrichtigungen/artikel">
            {t("main.interactionNotifications.tabs.newArticle")}
          </TabHeader>
          <TabHeader id="jobs" url="/benachrichtigungen/jobs">
            {t("main.interactionNotifications.tabs.newJobs")}
          </TabHeader>
        </TabHeaders>
        <TabBodies>
          <TabBody id="all">
            <></>
          </TabBody>
          <TabBody id="follower">
            <></>
          </TabBody>
          <TabBody id="friends">
            <></>
          </TabBody>
          <TabBody id="answers">
            <></>
          </TabBody>
          <TabBody id="mentions">
            <></>
          </TabBody>
          <TabBody id="quotes">
            <></>
          </TabBody>
          <TabBody id="reposts">
            <></>
          </TabBody>
          <TabBody id="verified">
            <></>
          </TabBody>
          <TabBody id="articles">
            <></>
          </TabBody>
          <TabBody id="jobs">
            <></>
          </TabBody>
        </TabBodies>
      </TabController>
    </>
  );
};

export default NotificationsContainer;
