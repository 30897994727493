import api, { RequestWithParams, requestParams } from "@src/api/api";
import { PostType } from "@src/types/PostType";

type FeedParams = {
  page?: number | null;
  page_size?: number | null;
  q?: string | null;
  user_id?: string | null;
  liked_by?: string | null;
  sort_by?: string | null;
  sort_direction?: string | null;
  status?: string | null;
  filter?: string | null;
  type?: string | null;
};

type FeedRequest = RequestWithParams<FeedParams>;

export type FeedResponse = {
  data: PostType[];
  current_page: number;
  total_pages: number;
};

export default async function feed(request: FeedRequest): Promise<FeedResponse> {
  const { data } = await api.get(`/api/v2/posts${requestParams({ ...request.params })}`);
  return data;
}
