import "@src/components/elements/posts/form/shared/editorTheme.css";

import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { HashtagNode } from "@lexical/hashtag";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { TRANSFORMERS } from "@lexical/markdown";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { HashtagPlugin } from "@lexical/react/LexicalHashtagPlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { NodeEventPlugin } from "@lexical/react/LexicalNodeEventPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { MentionNode } from "@src/components/elements/posts/form/shared/MentionNode";
import PostText from "@src/components/elements/posts/shared/PostText";
import editTheme from "@src/utils/editTheme";
import isMobile from "@src/utils/isMobile";

type Props = {
  value: string;
  disableTopMargin?: boolean;
  singleLine?: boolean;
  hashtagType?: "feed" | "watch";
};

function onError(error: Error) {
  console.log(error);
}

const theme = editTheme("editor-placeholder");

const InitialEditorStatePlugin = ({ initialState }: { initialState: string }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const state = editor.parseEditorState(initialState);
    editor.setEditorState(state);
  }, [editor, initialState]);

  return null;
};

const TextView = ({ value, disableTopMargin, singleLine, hashtagType }: Props) => {
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(false);
  const [largeText, setLargeText] = useState(false);
  const body = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (body.current && body.current.clientHeight > 64) setLargeText(true);
  }, [body]);

  try {
    JSON.parse(value);
    const initialConfig = {
      namespace: "PostView",
      editable: false,
      theme,
      onError,
      nodes: [
        HeadingNode,
        ListNode,
        ListItemNode,
        QuoteNode,
        CodeNode,
        CodeHighlightNode,
        TableNode,
        TableCellNode,
        TableRowNode,
        AutoLinkNode,
        LinkNode,
        MentionNode,
        HashtagNode,
      ],
    };

    let hashtagLink = "";
    if (hashtagType) {
      hashtagLink = `/${hashtagType}`;
    }

    return (
      <div className={`flex w-full gap-3 ${!disableTopMargin && "mt-3"}`}>
        <div className="flex grow text-sm">
          <div className="grid w-full grid-cols-1 gap-1">
            <LexicalComposer initialConfig={initialConfig}>
              <div
                onClick={() => {
                  if (isMobile) setShowMore(value => !value);
                }}
                aria-hidden
              >
                <div
                  className={`editor-inner leading-4 ${!showMore && largeText && "max-h-[50px] overflow-hidden"} ${singleLine && "line-clamp-1"}`}
                  ref={body}
                >
                  <RichTextPlugin
                    contentEditable={<ContentEditable />}
                    placeholder={<></>}
                    ErrorBoundary={LexicalErrorBoundary}
                  />
                  <InitialEditorStatePlugin initialState={value} />
                  <HistoryPlugin />
                  <ListPlugin />
                  <LinkPlugin />
                  <HashtagPlugin />
                  <NodeEventPlugin
                    eventListener={e => {
                      const event = e as PointerEvent;
                      const element = event.target as HTMLElement;
                      const parent = element.parentElement as HTMLAnchorElement;

                      const hastagRegex = /#([a-zA-ZäöüÄÖÜß]+)/g;
                      if (hastagRegex.test(element.innerText)) {
                        navigate(`/tags/${element.innerText.replace("#", "")}${hashtagLink}`);
                        event.preventDefault();
                        return;
                      }
                      window.open(parent.href, "_blank");
                      event.preventDefault();
                    }}
                    eventType="click"
                    nodeType={LinkNode}
                  />
                  <NodeEventPlugin
                    eventListener={e => {
                      const event = e as PointerEvent;
                      const element = event.target as HTMLElement;
                      navigate(`/tags/${element.innerText.replace("#", "")}${hashtagLink}`);
                      event.preventDefault();
                    }}
                    eventType="click"
                    nodeType={HashtagNode}
                  />
                  <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
                </div>
                {!showMore && largeText && (
                  <button
                    className="text-gray-500"
                    onClick={() => {
                      if (!isMobile) setShowMore(value => !value);
                    }}
                  >
                    {t("buttons.showMore")}
                  </button>
                )}
                {showMore && largeText && (
                  <button
                    className="text-gray-500"
                    onClick={() => {
                      if (!isMobile) setShowMore(value => !value);
                    }}
                  >
                    {t("buttons.showLess")}
                  </button>
                )}
              </div>
            </LexicalComposer>
          </div>
        </div>
      </div>
    );
  } catch {
    return <PostText text={value} disableTopMargin={disableTopMargin} hashtagType={hashtagType} />;
  }
};

export default TextView;
