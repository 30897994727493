import requests from "@src/api/contacts/requests";
import { useInfiniteQueryOnScroll } from "@src/hooks/useInfiniteQueryOnScroll";
import ContactItem from "@src/pages/contacts/ContactItem";

type Props = {
  type: "sent" | "received";
  searchQuery?: string;
};

const ContactRequestsContainer = ({ type, searchQuery }: Props) => {
  const { result: requestsResult } = useInfiniteQueryOnScroll({
    queryKey: ["contacts-requests", searchQuery, type],
    queryFn: ({ pageParam }) => requests({ type, params: { page: pageParam, q: searchQuery } }),
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      const nextPage = lastPage.current_page + 1;
      return nextPage <= lastPage.total_pages ? nextPage : null;
    },
  });

  return (
    <>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
        {requestsResult.data?.pages.map(page =>
          page.data.map(item => (
            <ContactItem
              key={item.id + "/" + item.id}
              mode={type === "sent" ? "requests-sent" : "requests-received"}
              user={item}
            />
          )),
        )}
      </div>
    </>
  );
};

export default ContactRequestsContainer;
