import feed from "@src/api/feed/feed";
import { useInfiniteQueryOnScroll } from "@src/hooks/useInfiniteQueryOnScroll";
import ProfileFeedList from "@src/pages/profile/details/ProfileFeedList";

type Props = {
  searchQuery?: string;
  showPlaceholder?: boolean;
};

const News = ({ searchQuery, showPlaceholder }: Props) => {
  const queryParams = {
    q: searchQuery ?? null,
    user_id: null,
    liked_by: null,
  };

  const { result: feedResult } = useInfiniteQueryOnScroll({
    queryKey: ["posts", "feed", queryParams],
    queryFn: ({ pageParam }) =>
      feed({
        params: {
          ...queryParams,
          page: pageParam,
        },
      }),
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      const nextPage = lastPage.current_page + 1;
      return nextPage <= lastPage.total_pages ? nextPage : null;
    },
  });

  if (!feedResult.isSuccess) return <></>;

  return (
    <>
      {showPlaceholder && <div key="news-placeholder" className="h-[35px]" />}
      <div
        key="news"
        className="grid grid-cols-2 gap-1 md:grid-cols-3 xl:grid-cols-4 xl:gap-3 2xl:grid-cols-5"
      >
        <ProfileFeedList placeholder="/placeholder.png" feedResult={feedResult} />
      </div>
    </>
  );
};

export default News;
