import api, { RequestWithParams } from "@src/api/api";

type ActivateParams = {
  code: string;
};

type ActivateRequest = RequestWithParams<ActivateParams>;

export default async function activate(request: ActivateRequest): Promise<void> {
  const { data } = await api.post("/api/v1/activate", request.params);
  return data;
}
