import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type Props = {
  path?: string;
};

const MarketplaceRedirectContainer = ({ path }: Props) => {
  const { t } = useTranslation();

  useEffect(() => {
    window.location.href = `${import.meta.env.VITE_MARKETPLACE_URL}${path}`;
  });

  return (
    <Link to={`${import.meta.env.VITE_MARKETPLACE_URL}${path}`}>
      {t("marketplace.redirecting")}
    </Link>
  );
};

export default MarketplaceRedirectContainer;
