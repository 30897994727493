import { ArrowPathRoundedSquareIcon } from "@heroicons/react/24/outline";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import like from "@src/api/posts/like";
import unlike from "@src/api/posts/unlike";
import view from "@src/api/posts/view";
import Panel from "@src/components/box/Panel";
import LinkPreview from "@src/components/elements/LinkPreview";
import UserWidget from "@src/components/elements/UserWidget";
import PostCommentSection from "@src/components/elements/posts/comments/PostCommentSection";
import Lightbox from "@src/components/elements/shared/Lightbox";
import PostGallery from "@src/components/elements/shared/PostGallery";
import TextView from "@src/components/elements/textedit/TextView";
import { useVisibility } from "@src/hooks/useVisibility";
import PostsActionBar from "@src/pages/news/posts/PostsActionBar";
import PostsDropdown from "@src/pages/news/posts/PostsDropdown";
import { useAppSelector } from "@src/state/hooks";
import { PostType } from "@src/types/PostType";
import { UserType } from "@src/types/UserType";
import checkHideLinkText from "@src/utils/checkHideLinkText";
import getName from "@src/utils/getName";
import removeLinkFromLexicalObject from "@src/utils/removeLinkFromLexicalObject";

type Props = {
  postId: string;
  post: PostType;
  author: UserType;
  text: string;
  published_at: string;
  key?: string;
  onSwitch?: (selectedPost: string) => void;
  lightboxSelection?: string;
  next?: string;
  prev?: string;
};

export function formatDateDifference(currentDate: Date, postDate: Date): string {
  const diffInSeconds = Math.floor((currentDate.getTime() - postDate.getTime()) / 1000);
  const mins = Math.floor(diffInSeconds / 60);
  const hours = Math.floor(mins / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);

  if (mins < 1) return "Gerade eben";
  if (mins === 1) return "Vor einer 1 Min";
  if (mins < 60) return `vor ${mins} Min`;
  if (hours === 1) return "Vor 1 h";
  if (hours < 24) return `vor ${hours} h`;
  if (days === 1) return "Vor 1 T";
  if (days < 7) return `vor ${days} T`;
  if (weeks === 1) return "Vor 1 W";
  return `vor ${weeks} W`;
}

const RepostUser = ({ currentPost }: { currentPost?: PostType }) => {
  const location = useLocation();
  const { t } = useTranslation();

  if (currentPost?.repost)
    return (
      <NavLink
        to={`/profile/${currentPost.repost.author.id}`}
        state={{
          backlink: location.state?.backlink ?? location.pathname,
        }}
        className="flex flex-row items-center gap-1 truncate text-gray-400"
      >
        <ArrowPathRoundedSquareIcon className="size-4 shrink-0" />
        <span className="text-xs font-medium">{getName(currentPost.repost.author)}</span>
        <div className="whitespace-nowrap text-xs font-medium">
          {t("components.posts.repostedBy")}
        </div>
      </NavLink>
    );

  return <></>;
};

const PostWidget = ({ author, published_at, ...props }: Props) => {
  const { user } = useAppSelector(state => state.user);
  const { t } = useTranslation();
  const [openComments, setOpenComments] = useState(false);
  const [playingVideo, setPlayingVideo] = useState<string | null>(null);
  const [downloadRunning, setDownloadRunning] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const queryClient = useQueryClient();
  const [isVisible, ref] = useVisibility();
  const [viewed, setViewed] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const containerRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const videoRefs = useRef<{ [key: string]: ReactPlayer | null }>({});

  const linkPreview = props.post.link_preview;
  const text = removeLinkFromLexicalObject(props.text, props.post.link);

  const media = props.post.media;
  const hideLinkText = linkPreview && (linkPreview.url === text || checkHideLinkText(text));

  const likeMutation = useMutation({
    mutationKey: [`like-${props.post.id}-${user?.id}`],
    mutationFn: () => like({ id: props.post.id }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  const unlikeMutation = useMutation({
    mutationKey: [`unlike-${props.post.id}-${user?.id}`],
    mutationFn: () => unlike({ id: props.post.id }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts"] });
    },
    onError: () => {
      alert("Failed");
    },
  });

  useEffect(() => {
    if (isVisible && !viewed) {
      view({ id: props.post.id })
        .then(() => setViewed(true))
        .catch(error => {
          if (error instanceof AxiosError && error.response?.status === 404) {
            return;
          }
          throw error;
        });
    }
  }, [isVisible, props.post.id, viewed]);

  useEffect(() => {
    const currentContainerRefs = { ...containerRefs.current };

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const videoId = entry.target.getAttribute("data-video-id");
            setPlayingVideo(videoId);
          } else {
            const videoId = entry.target.getAttribute("data-video-id");
            if (playingVideo === videoId) {
              setPlayingVideo(null);
            }
          }
        });
      },
      {
        threshold: 0.5, // Trigger if 80% of the video container is visible
      },
    );
    Object.values(currentContainerRefs).forEach(containerRef => {
      if (containerRef) {
        observer.observe(containerRef);
      }
    });
    return () => {
      Object.values(currentContainerRefs).forEach(containerRef => {
        if (containerRef) {
          observer.unobserve(containerRef);
        }
      });
    };
  }, [playingVideo]);

  return (
    <Panel mobileBorder={false}>
      <div ref={ref}>
        <div className="flex w-full gap-3">
          <div className="w-[35px]">
            <UserWidget user={author} size={32} follow={false} online={false} />
          </div>
          <div className="flex-1">
            <RepostUser currentPost={props.post} />
            <div className="flex flex-col">
              <div className="flex flex-row items-center justify-between">
                <div className="flex flex-1 items-center gap-3">
                  <NavLink
                    to={`/profile/${author.id}`}
                    state={{
                      backlink: location.state?.backlink ?? location.pathname,
                    }}
                    className="flex flex-row gap-1 truncate"
                  >
                    <div className="min-w-0 flex-1">
                      <p className="truncate whitespace-normal text-sm font-medium text-gray-900 dark:text-black">
                        {(getName(author) || "").length > 30
                          ? `${(getName(author) ?? "").substring(0, 30)}...`
                          : getName(author)}
                      </p>
                      <p className="truncate text-xs text-gray-500 dark:text-gray-400">
                        @
                        {(author.username || "").length > 20
                          ? `${(author.username || "").substring(0, 20)}...`
                          : author.username}
                      </p>
                    </div>
                    {author.type === "person" && (
                      <img
                        alt=""
                        src="/images/person_verify.svg"
                        className="mt-1 size-4 shrink-0"
                      />
                    )}
                    {author.type === "company" && (
                      <img
                        alt=""
                        src="/images/company_verify.svg"
                        className="mt-1 size-4 shrink-0"
                      />
                    )}
                    {author.type === "club" && (
                      <img alt="" src="/images/club_verify.svg" className="mt-1 size-4 shrink-0" />
                    )}
                  </NavLink>
                </div>
                <div
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  aria-hidden
                >
                  <PostsDropdown
                    post={props.post}
                    onDownloadStart={() => setDownloadRunning(true)}
                    onDownloadEnd={() => setDownloadRunning(false)}
                    onDownloadProgress={progress => setDownloadProgress(progress)}
                  />
                </div>
              </div>
              <div className="text-[0.7rem] leading-4 text-gray-600">
                {formatDateDifference(new Date(), new Date(published_at))}
              </div>
            </div>
            <div
              style={{
                maxWidth: ref.current?.offsetWidth ? ref.current?.offsetWidth - 45 : "100%",
              }}
              className="break-words"
            >
              {props.lightboxSelection === props.post.id && (
                <Lightbox
                  onClose={() => {
                    if (props.onSwitch) props.onSwitch("");
                  }}
                  onSwitch={(id: string) => props.onSwitch && props.onSwitch(id)}
                  post={props.post}
                  prev={props.prev}
                  next={props.next}
                />
              )}
              {downloadRunning && (
                <Panel>
                  {t("components.posts.downloading")}
                  <div className="h-2.5 w-full rounded-full">
                    <div
                      className="h-2.5 rounded-full bg-darkblue"
                      style={{ width: `${downloadProgress}%` }}
                    ></div>
                  </div>
                </Panel>
              )}
              {linkPreview && <LinkPreview link={linkPreview} />}
              {media.length > 0 && media[0].type === "image" && (
                <PostGallery
                  media={media}
                  onClick={() => props.onSwitch && props.onSwitch(props.postId)}
                />
              )}
              {media.length > 0 && media[0].type === "video" && (
                <div
                  className="mt-3 aspect-square cursor-pointer overflow-hidden rounded-2xl border border-gray-400"
                  onClick={() => navigate(`/watch/für_dich?featured=${media[0].key}`)}
                  ref={el => {
                    containerRefs.current["video-2"] = el;
                  }}
                  data-video-id="video-2"
                  aria-hidden
                >
                  <ReactPlayer
                    width="100%"
                    height="100%"
                    className="react-player"
                    url={`${media[0].data_url}/playlist.m3u8`}
                    playing={playingVideo === "video-2"}
                    controls={false}
                    muted={true}
                    ref={el => {
                      videoRefs.current["video-2"] = el;
                    }}
                  />
                </div>
              )}
              {!hideLinkText && text && <TextView value={text} hashtagType="feed" />}
            </div>
            <PostsActionBar
              toggleComments={() => setOpenComments(value => !value)}
              toggleLike={() => {
                if (user) {
                  if (props.post.liked) {
                    unlikeMutation.mutate();
                  } else {
                    likeMutation.mutate();
                  }
                }
              }}
              isLiked={props.post.liked}
              isReposted={props.post.reposted}
              likeCount={props.post.like_count}
              viewCount={props.post.view_count}
              bookmarkCount={props.post.bookmark_count}
              repostCount={props.post.repost_count}
              bookmarked={props.post.bookmarked}
              postId={props.post.id}
              commentCount={props.post.comment_count}
            />
          </div>
        </div>
      </div>
      {openComments && (
        <PostCommentSection postId={props.postId} viewCount={props.post.view_count} />
      )}
    </Panel>
  );
};

export default PostWidget;
