import React from "react";

type Props = {
  children?: React.ReactNode;
  fallback?: React.ReactElement;
};

type State = {
  error?: Error;
};

class ChunkLoadErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: unknown) {
    if (
      error instanceof Error &&
      (error.name === "ChunkLoadError" ||
        error.message.includes("Failed to fetch dynamically imported module"))
    ) {
      return { error };
    }

    throw error;
  }

  render() {
    if (this.state.error) {
      return this.props.fallback;
    }

    return this.props.children;
  }
}

export default ChunkLoadErrorBoundary;
