import { useMemo } from "react";

import JobsSuggestions from "@src/components/elements/sidebar/suggestions/JobsSuggestions";
import NewsfeedSuggestions from "@src/components/elements/sidebar/suggestions/NewsfeedSuggestions";
import WatchSuggestions from "@src/components/elements/sidebar/suggestions/WatchSuggestions";

type Props = {
  id?: string;
  key?: string;
};

const SuggestionsContainer = ({ id }: Props) => {
  const seed = useMemo(() => {
    return Math.floor(Math.random() * 60);
  }, []);

  if (seed < 20) {
    return <NewsfeedSuggestions id={id} key={id} />;
  } else if (seed < 40) {
    return <JobsSuggestions id={id} key={id} />;
  } else {
    return <WatchSuggestions id={id} key={id} />;
  }
};

export default SuggestionsContainer;
