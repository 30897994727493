import { Mutex } from "async-mutex";
import axios from "axios";

import { store } from "@src/state/store";
import { setRefreshToken, setToken, signOut } from "@src/state/user/userSlice";

type Params = Record<string, string | number | boolean | null | undefined>;

export type RequestWithData<T> = {
  data: T;
};

export type RequestWithFile = {
  file: File;
};

export type RequestWithId = {
  id: string;
};

export type RequestWithParams<T extends Params> = {
  params: T;
};

export function requestParams<T extends Params>(params?: T): string {
  const searchParams = new URLSearchParams();

  Object.entries(params ?? {}).forEach(([key, value]) => {
    if (value === null || value === undefined || value === "") {
      return;
    }
    searchParams.append(key, value.toString());
  });

  if (searchParams.size === 0) {
    return "";
  }

  return `?${searchParams}`;
}

const mutex = new Mutex();

const api = axios.create({
  baseURL: __API_URL || "",
});

api.interceptors.request.use(
  async config => {
    await mutex.waitForUnlock();

    const token = store.getState().user.token;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        originalRequest._retry = true;
        try {
          const { data } = await axios.post(
            "/api/v1/auth/refresh",
            {
              token: store.getState().user.refreshToken,
            },
            { baseURL: __API_URL || "" },
          );
          store.dispatch(setToken(data.access_token));
          store.dispatch(setRefreshToken(data.refresh_token));
          return api(originalRequest);
        } catch (refreshError) {
          store.dispatch(signOut());
          setTimeout(() => {
            window.location.href = "/login";
          }, 200);
          return Promise.reject(refreshError);
        } finally {
          release();
        }
      } else {
        await mutex.waitForUnlock();
        return api(originalRequest);
      }
    }
    return Promise.reject(error);
  },
);

export default api;
