import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import PopupWrapper from "@src/components/elements/PopupWrapper";
import Button from "@src/components/elements/input/Button";

type Props = {
  onClose?: () => void;
};

const AppPromoPopup = ({ onClose }: Props) => {
  const { t } = useTranslation();

  const getDescription = () => {
    const pathname = useLocation().pathname;
    if (pathname.startsWith("/watch")) return t("main.panel.promoAppPopUp.description.watch");
    if (pathname.startsWith("/jobs") || pathname.startsWith("/verwaltung/jobs/"))
      return t("main.panel.promoAppPopUp.description.jobs");
    if (pathname.startsWith("/feed")) return t("main.panel.promoAppPopUp.description.news");
    if (pathname.startsWith("/marketplace/listings"))
      return t("main.panel.promoAppPopUp.description.inserate");
    if (pathname.startsWith("/marketplace"))
      return t("main.panel.promoAppPopUp.description.marketplace");
    return t("main.panel.promoAppPopUp.description.watch");
  };

  const openApp = () => {
    window.location.href = "https://tradefoox.app.link";
  };

  return (
    <PopupWrapper
      onClose={onClose}
      superTitle={
        <img src="/logo512.png" className="size-14 rounded-full bg-white p-0.5" alt="Logo" />
      }
      windowStyle="w-[80vw]"
    >
      <div className="text-center">
        <h2 className="mb-4 text-xl font-bold">{t("main.panel.promoAppPopUp.title")}</h2>
        <p className="mb-6 text-gray-600">{getDescription()}</p>
        <div className="flex justify-center gap-4">
          <a href="app.tradefoox.mobile:">
            <Button variant={Button.Variants.Red} size={Button.Sizes.Large} onClick={openApp}>
              {t("main.panel.promoAppPopUp.download")}
            </Button>
          </a>
        </div>
      </div>
    </PopupWrapper>
  );
};

export default AppPromoPopup;
