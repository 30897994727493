import { useMutation, useQueryClient } from "@tanstack/react-query";

import like from "@src/api/posts/like";
import unlike from "@src/api/posts/unlike";
import { PostType } from "@src/types/PostType";

type Props = {
  data: PostType;
  queryKey?: (string | null)[];
};

const useWatchLike = ({ data, queryKey = ["watch", "posts"] }: Props) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [`like-${data.id}`],
    mutationFn: async () => {
      const liked = data.liked;

      await queryClient.cancelQueries({ queryKey });
      const previousData = queryClient.getQueryData<{
        pages: Array<{ data: PostType[] }>;
      }>(queryKey);

      if (previousData) {
        for (const page of previousData.pages) {
          for (const post of page.data) {
            if (post.id !== data.id) continue;

            post.like_count += data.liked ? -1 : 1;
            post.liked = !liked;
          }
        }
        queryClient.setQueryData(queryKey, previousData);
      }

      if (liked) {
        return await unlike({ id: data.id });
      }

      return await like({ id: data.id });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts", "watch"] });
    },
    onError: () => {
      alert("Failed");
    },
  });
};

export default useWatchLike;
