import api, { RequestWithParams, requestParams } from "@src/api/api";
import { UserType } from "@src/types/UserType";

type MyContactsParams = {
  page?: number;
  q?: string;
};

type MyContactsRequest = RequestWithParams<MyContactsParams>;

type MyContactsResponse = {
  data: UserType[];
  current_page: number;
  total_pages: number;
};

export default async function myContacts(request: MyContactsRequest): Promise<MyContactsResponse> {
  const { data } = await api.get(
    `/api/v1/contacts${requestParams({ page: 1, ...request.params, filter: "my" })}`,
  );
  return data;
}
