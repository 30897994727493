import { useState } from "react";

import posts from "@src/api/hashtags/posts";
import PostWidget from "@src/components/elements/PostWidget";
import { useInfiniteQueryOnScroll } from "@src/hooks/useInfiniteQueryOnScroll";
import { PostType } from "@src/types/PostType";
import nextPost from "@src/utils/nextPost";
import prevPost from "@src/utils/prevPost";

type Props = {
  hashtag?: string;
  searchQuery?: string;
  type?: string;
};

const PostsContainer = ({ hashtag, searchQuery, type }: Props) => {
  const [lightboxSelection, setLightboxSelection] = useState("");

  const { result: postsResult } = useInfiniteQueryOnScroll({
    queryKey: ["posts", hashtag],
    queryFn: async ({ pageParam }) =>
      posts({
        params: {
          page: pageParam,
          q: searchQuery,
          type: type,
        },
        hashtag: `${hashtag}`,
      }),
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      const nextPage = lastPage.current_page + 1;
      return nextPage <= lastPage.total_pages ? nextPage : null;
    },
  });

  return (
    <>
      {postsResult.data?.pages.map((page, pageIndex) =>
        page?.data?.map(
          (post: PostType, index: number) =>
            (type === "watch"
              ? post.media?.[0]?.type === "video"
              : post.media?.[0]?.type === "image") && (
              <PostWidget
                postId={post.repost ? post.repost.id : post.id}
                post={post}
                key={post.repost ? post.repost.id : post.id}
                text={post.text}
                author={post.author}
                published_at={post.published_at}
                onSwitch={(id: string) => {
                  setLightboxSelection(id);
                }}
                lightboxSelection={lightboxSelection}
                prev={prevPost({ index, pageIndex, postsResult })}
                next={nextPost({ index, pageIndex, postsResult })}
              />
            ),
        ),
      )}
    </>
  );
};

export default PostsContainer;
