import api, { RequestWithParams, requestParams } from "@src/api/api";
import { PostType } from "@src/types/PostType";

type WatchParams = {
  page?: number;
  q?: string;
  featured_media_id?: string | null;
  user_id?: string | null;
  liked_by?: string | null;
  bookmarked?: boolean;
  following?: boolean;
};

type WatchRequest = RequestWithParams<WatchParams>;

export type WatchResponse = {
  data: PostType[];
  current_page: number;
  total_pages: number;
};

export default async function watch(request: WatchRequest): Promise<WatchResponse> {
  const { data } = await api.get(
    `/api/v2/posts${requestParams({ type: "watch", ...request.params })}`,
  );
  return data;
}
