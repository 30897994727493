import { UserType } from "@src/types/UserType";

const getName = (user: UserType) => {
  if (user.person || user.company || user.club) {
    if (user.type === "person" && user.person)
      return `${user.person.firstname} ${user.person.lastname}`;
    if (user.type === "company" && user.company) return user.company.name;
    if (user.type === "club" && user.club) return user.club.name;
  } else {
    if (user.type === "person") return `${user.first_name} ${user.last_name}`;
    if (user.type === "company" || user.type === "club") return user.name;
  }
  return undefined;
};

export default getName;
