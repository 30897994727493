import { useTranslation } from "react-i18next";

import forYou from "@src/api/contacts/forYou";
import TabBodies from "@src/components/elements/shared/tab/TabBodies";
import TabBody from "@src/components/elements/shared/tab/TabBody";
import TabController from "@src/components/elements/shared/tab/TabController";
import TabHeader from "@src/components/elements/shared/tab/TabHeader";
import TabHeaders from "@src/components/elements/shared/tab/TabHeaders";
import { useInfiniteQueryOnScroll } from "@src/hooks/useInfiniteQueryOnScroll";
import ContactItem from "@src/pages/contacts/ContactItem";
import { UserType } from "@src/types/UserType";

type Props = {
  tab?: string;
  searchQuery?: string;
};

const Profiles = ({ tab, searchQuery }: Props) => {
  const { t } = useTranslation();

  const { result: forYourResult } = useInfiniteQueryOnScroll({
    queryKey: ["contacts", "for-you", searchQuery, tab],
    queryFn: ({ pageParam }) =>
      forYou({ params: { type: tab ?? null, page: pageParam, q: searchQuery ?? null } }),
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      const nextPage = lastPage.current_page + 1;
      return nextPage <= lastPage.total_pages ? nextPage : null;
    },
  });

  const tabContent = (
    <div className="grid grid-cols-2 gap-1 md:grid-cols-3 xl:grid-cols-4 xl:gap-3 2xl:grid-cols-5">
      {forYourResult.data?.pages.map(page =>
        page.data.map((item: UserType) => <ContactItem key={item.id} mode="default" user={item} />),
      )}
    </div>
  );

  return (
    <TabController defaultTab={tab} isSubTab>
      <TabHeaders headerCSS="h-[35px]" center>
        <TabHeader url="/entdecken/profile/alle" id="">
          {t("components.shared.all")}
        </TabHeader>
        <TabHeader url="/entdecken/profile/personen" id="person">
          {t("main.contacts.tabs.person")}
        </TabHeader>
        <TabHeader url="/entdecken/profile/firmen" id="company">
          {t("main.contacts.tabs.company")}
        </TabHeader>
        <TabHeader url="/entdecken/profile/vereine" id="club">
          {t("main.contacts.tabs.club")}
        </TabHeader>
        <TabHeader url="/entdecken/profile/gruppen" id="group">
          {t("main.header.links.groups.title")}
        </TabHeader>
      </TabHeaders>
      <TabBodies>
        <TabBody id="">{tabContent}</TabBody>
        <TabBody id="person">{tabContent}</TabBody>
        <TabBody id="company">{tabContent}</TabBody>
        <TabBody id="club">{tabContent}</TabBody>
        <TabBody id="group">{tabContent}</TabBody>
      </TabBodies>
    </TabController>
  );
};

export default Profiles;
