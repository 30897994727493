import { InfiniteData, UseInfiniteQueryResult } from "@tanstack/react-query";

import { FeedResponse } from "@src/api/feed/feed";
import CompactPost from "@src/components/elements/CompactPost";

type Props = {
  placeholder?: string;
  feedResult: UseInfiniteQueryResult<InfiniteData<FeedResponse>>;
};

const ProfileFeedList = ({ placeholder, feedResult }: Props) => {
  if (!feedResult.isSuccess) return <></>;

  return (
    <>
      {feedResult.data.pages
        .flatMap(page => page.data)
        .map(post => {
          if (!post) return;

          let id = post.id;
          let type: "image" | "video" = "image";
          let url = placeholder;

          if (post.link_preview?.image_url) {
            url = `${post.link_preview?.image_url}`;
          } else if (post.media.length) {
            id = post.media[0].key;
            url = post.media[0].data_url;
            if (post.media[0].type === "video") {
              type = "video";
            }
          }

          return (
            <CompactPost
              key={`discover:${post.media ? id : post.id}`}
              link={
                type === "video"
                  ? `/watch/für_dich?featured=${id}`
                  : `/news/für_dich?featured=${post.id}`
              }
              type={type}
              url={url}
              viewCount={post.view_count}
              likeCount={post.like_count}
            />
          );
        })}
    </>
  );
};

export default ProfileFeedList;
