import { useContext, useState } from "react";
import { Outlet } from "react-router-dom";

import { AppContext } from "@src/App";
import UserDetailsWidgetWrapper from "@src/components/elements/UserDetailsWidgetWrapper";
import SideBarMenu from "@src/components/elements/sidebar/widgets/SideBarMenu";
import CreateFormButton from "@src/components/nav/CreateFormButton";
import NavBar from "@src/components/nav/NavBar";
import NavBarBottom from "@src/components/nav/NavBarBottom";
import useAppPromoPopup from "@src/hooks/useAppPromoPopup";
import usePopupModal from "@src/state/modal/usePopupModal";
import isMobile from "@src/utils/isMobile";

type Props = {
  darkHeader?: boolean;
  darkFooter?: boolean;
  sidebarButtonVisible?: boolean;
  smallSideBar?: boolean;
  showBG?: boolean;
};

const FullscreenMainWrapper = ({ ...props }: Props) => {
  const appContext = useContext(AppContext);

  const popupModal = usePopupModal();

  const [sidebarVisible, setSidebarVisible] = useState(false);
  const sideBarOnClick = props.sidebarButtonVisible
    ? () => {
        setSidebarVisible(!sidebarVisible);
      }
    : undefined;

  useAppPromoPopup();

  return (
    <div
      className={`mb-[env(safe-area-inset-bottom)] mt-[env(safe-area-inset-top)] grid grid-rows-[50px,calc(calc(100dvh-90px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom))),40px] xl:grid-rows-[50px,calc(calc(100dvh-50px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))] ${(appContext?.fullscreen || appContext?.topNavHidden) && "grid grid-rows-[calc(100dvh-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))] md:grid-rows-[50px,calc(calc(100dvh-50px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))]"} ${appContext?.darkTheme && "max-md:bg-neutral-900 max-md:text-white"} ${!appContext?.darkTheme && "bg-white text-black"}`}
    >
      <div className={`${(appContext?.fullscreen || appContext?.topNavHidden) && "max-md:hidden"}`}>
        <NavBar dark={props.darkHeader} sideBarOnClick={sideBarOnClick} />
      </div>

      <div className={`relative ${props.showBG && "bg-[#E3E6E6]"}`}>
        <main className="flex size-full max-h-[calc(calc(100dvh-90px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))] flex-row overflow-y-scroll scrollbar-thin xl:max-h-[calc(calc(100dvh-50px)-calc(env(safe-area-inset-top)+env(safe-area-inset-bottom)))]">
          {!isMobile && (
            <aside
              className={`sticky left-0 top-0 shrink-0 text-black delay-1000 max-xl:hidden ${props.smallSideBar ? "w-[220px] bg-transparent" : "w-[365px] border-r border-gray-500 bg-white"}`}
            >
              <SideBarMenu small={props.smallSideBar} />
              <div className="px-2">
                <CreateFormButton />
              </div>
            </aside>
          )}
          <div
            className={`mx-auto grid w-full grid-flow-row auto-rows-max grid-cols-1 justify-center gap-3 overflow-x-clip px-4 max-md:p-4 md:py-4 xl:max-w-[1400px] ${(appContext?.borderless || appContext?.fullscreen) && "m-0 p-0"}`}
          >
            <Outlet />
          </div>
        </main>
      </div>
      <div
        className={`${(appContext?.fullscreen || appContext?.bottomNavHidden) && "max-md:hidden"}`}
      >
        <NavBarBottom dark={props.darkFooter} />
      </div>
      {popupModal.stack.length > 0 && popupModal.stack[0]}
      <UserDetailsWidgetWrapper />
    </div>
  );
};

export default FullscreenMainWrapper;
