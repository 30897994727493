import { TFunction } from "i18next";
import {
  Bookmark,
  Discover,
  DocumentText,
  Edit,
  Edit2,
  Element2,
  Gift,
  Heart,
  ImportSquare,
  Note,
  Notification,
  Profile2User,
  Setting2,
  Shop,
  ShoppingBag,
  ShoppingCart,
  Sms,
  Tag,
  User,
  Video,
  Wallet,
} from "iconsax-react";
import { ReactNode } from "react";

export type ListType = {
  href: string;
  label: string;
  icon: ReactNode;
  solidIcon: ReactNode;
  style?: string;
  notification?: { count: number; minimum: number; link: string; type: string };
  reverse?: boolean;
  children?: Array<ListType>;
  extern?: boolean;
  authRequired?: boolean;
};

const NavSideLinkList = (t: TFunction): Array<ListType> => [
  {
    href: "",
    label: t("main.panel.settings"),
    icon: <Setting2 name="Setting2" size={18} />,
    solidIcon: <Setting2 name="Setting2" variant="Bold" size={18} />,
    style: "hidden xl:flex",
    authRequired: true,
    children: [
      {
        href: "/verwaltung/profile",
        label: t("main.panel.profileWidgets.editProfile"),
        icon: <Edit2 name="Edit2" size={18} />,
        solidIcon: <Edit2 name="Edit2" variant="Bold" size={18} />,
        authRequired: true,
      },
      {
        href: "/verwaltung/account",
        label: t("main.panel.profileWidgets.account"),
        icon: <User name="User" size={18} />,
        solidIcon: <User name="User" variant="Bold" size={18} />,
        authRequired: true,
      },
    ],
  },
  {
    href: "/benachrichtigungen",
    label: t("main.navLinks.notifications"),
    icon: <Notification name="Notification" size={18} />,
    solidIcon: <Notification name="Notification" variant="Bold" size={18} />,
    authRequired: true,
  },
  {
    href: "/news",
    label: t("main.navLinks.news"),
    icon: <DocumentText name="DocumentText" size={18} />,
    solidIcon: <DocumentText name="DocumentText" variant="Bold" size={18} />,
  },
  {
    href: "/watch",
    label: t("main.navLinks.watch"),
    icon: <Video name="Video" size={18} />,
    solidIcon: <Video name="Video" variant="Bold" size={18} />,
  },
  {
    href: "/messenger",
    label: t("main.navLinks.messenger"),
    icon: <Sms name="Sms" size={18} />,
    solidIcon: <Sms name="Sms" variant="Bold" size={18} />,
    notification: { count: 0, minimum: 1, link: "", type: "number" },
    authRequired: true,
  },
  {
    href: "/kontakte",
    label: t("main.header.links.contacts"),
    icon: <Profile2User name="Profile2User" size={18} />,
    solidIcon: <Profile2User name="Profile2User" variant="Bold" size={18} />,
    notification: {
      count: 0,
      minimum: 1,
      link: "/kontakte/für_dich",
      type: "number",
    },
  },
  {
    href: "/verwaltung/jobs/jobs_erstellen",
    label: t("main.navLinks.jobsPublished.title"),
    icon: <Wallet name="Wallet" size={18} />,
    solidIcon: <Wallet name="Wallet" variant="Bold" size={18} />,
    notification: { count: 0, minimum: 1, link: "", type: "dot" },
    authRequired: true,
  },
  {
    href: `${import.meta.env.VITE_MARKETPLACE_URL}/listings/my-offers/add`,
    label: t("main.navLinks.createAd.title"),
    icon: <Tag name="Tag" size={18} />,
    solidIcon: <Tag name="Tag" variant="Bold" size={18} />,
    authRequired: true,
  },
  {
    href: `${import.meta.env.VITE_MARKETPLACE_URL}/offers`,
    label: t("main.navLinks.marketplace.title"),
    icon: <Shop name="Shop" size={18} />,
    solidIcon: <Shop name="Shop" variant="Bold" size={18} />,
    children: [
      {
        href: `${import.meta.env.VITE_MARKETPLACE_URL}/offers/order/cart`,
        label: t("main.navLinks.marketplace.card"),
        icon: <ShoppingCart name="ShoppingCart" size={18} />,
        solidIcon: <ShoppingCart name="ShoppingCart" variant="Bold" size={18} />,
        authRequired: true,
      },
      {
        href: `${import.meta.env.VITE_MARKETPLACE_URL}/offers/wishlist`,
        label: t("main.navLinks.marketplace.wishList"),
        icon: <Heart name="Heart" size={18} />,
        solidIcon: <Heart name="Heart" variant="Bold" size={18} />,
        authRequired: true,
      },
      {
        href: `${import.meta.env.VITE_MARKETPLACE_URL}/offers/account/purchases`,
        label: t("main.navLinks.marketplace.viewCard"),
        icon: <Gift name="Gift" size={18} />,
        solidIcon: <Gift name="Gift" variant="Bold" size={18} />,
        authRequired: true,
      },
    ],
  },
  {
    href: "/verwaltung/mein_shop/übersicht",
    label: t("main.navLinks.myShop.title"),
    icon: <ShoppingBag name="ShoppingBag" size={18} />,
    solidIcon: <ShoppingBag name="ShoppingBag" variant="Bold" size={18} />,
    authRequired: true,
    children: [
      {
        href: `${import.meta.env.VITE_MARKETPLACE_URL}/offers/account/my-offers/add`,
        label: t("main.navLinks.myShop.sell"),
        icon: <Edit name="Edit" size={18} />,
        solidIcon: <Edit name="Edit" variant="Bold" size={18} />,
        authRequired: true,
      },
      {
        href: `${import.meta.env.VITE_MARKETPLACE_URL}/offers/account/my-offers/templates`,
        label: t("main.navLinks.myShop.templates"),
        icon: <Note name="Note" size={18} />,
        solidIcon: <Note name="Note" variant="Bold" size={18} />,
        authRequired: true,
      },
      {
        href: `${import.meta.env.VITE_MARKETPLACE_URL}/offers/account/my-offers/import`,
        label: t("main.navLinks.myShop.import"),
        icon: <ImportSquare name="ImportSquare" size={18} />,
        solidIcon: <ImportSquare name="ImportSquare" variant="Bold" size={18} />,
        authRequired: true,
      },
    ],
  },
  {
    href: "/bookmarks",
    label: t("main.navLinks.watchLists"),
    icon: <Bookmark name="Bookmark" size={18} />,
    solidIcon: <Bookmark name="Bookmark" variant="Bold" size={18} />,
    authRequired: true,
  },
  {
    href: "/entdecken",
    label: t("main.navLinks.discover"),
    icon: <Discover name="Discover" size={18} />,
    solidIcon: <Discover name="Discover" variant="Bold" size={18} />,
  },
  {
    href: "/werbung-erstellen",
    label: t("main.navLinks.createAd.tradefooxAdd"),
    icon: <Element2 name="Element2" size={18} />,
    solidIcon: <Element2 name="Element2" variant="Bold" size={18} />,
  },
];

export default NavSideLinkList;
