import { ChevronLeftIcon, ChevronRightIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTus } from "use-tus";

import Button from "@src/components/elements/input/Button";
import useUploadMutation from "@src/utils/useUploadMutation";

type Props = {
  media: Array<{
    id: string;
    key: string;
    file_name: string;
    type: string;
    thumbnail_url: string;
    data_url?: string;
  }>;
  onChange: (media: {
    id: string;
    key: string;
    file_name: string;
    type: string;
    thumbnail_url: string;
    data_url?: string;
  }) => void;
  addClick?: {
    getFunction: (() => void) | undefined;
    setFunction: Dispatch<SetStateAction<(() => void) | undefined>>;
  };
  onDelete: (id: string) => void;
};
const PostMedia = ({ onChange, media, onDelete, addClick }: Props) => {
  const { t } = useTranslation();
  const [uploadingCount, setUploadingCount] = useState(0);
  const { upload, setUpload, isSuccess, remove } = useTus();
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (upload) {
      upload.start();
    }
  }, [upload]);

  useEffect(() => {
    if (isSuccess) {
      remove();
    }
  }, [isSuccess, remove]);

  const uploadMutation = useUploadMutation({
    uploadingCount,
    onChange,
    setUploadingCount,
    setUpload,
    upload,
  });

  const fileInputRef = useRef<HTMLInputElement>(null);
  const addMedia = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  };

  if (addClick?.setFunction && !addClick?.getFunction) {
    addClick.setFunction(() => {
      return addMedia;
    });
  }

  const scroll = (direction: string) => {
    if (!scrollRef.current) return;
    if (direction === "left") scrollRef.current.scrollBy({ left: -108, behavior: "smooth" });
    if (direction === "right") scrollRef.current.scrollBy({ left: 108, behavior: "smooth" });
  };

  return (
    <>
      {media.length + uploadingCount > 0 && (
        <div className="flex flex-row gap-3 rounded-xl py-2">
          {media.length + uploadingCount > 4 && (
            <button
              className="flex items-center rounded-full p-1 shadow"
              onClick={() => {
                scroll("left");
              }}
            >
              <ChevronLeftIcon className="size-5 text-gray-500" />
            </button>
          )}
          <div className="flex flex-row gap-3 overflow-x-hidden" ref={scrollRef}>
            {media.map(data => (
              <div key={data.id} className="relative">
                <button
                  onClick={() => {
                    onDelete(data.id);
                  }}
                  className="absolute right-3 top-3"
                >
                  <XMarkIcon className="size-6 rounded-full bg-white opacity-90" />
                </button>
                {data.type === "image" && (
                  <img
                    alt=""
                    className="aspect-square size-24 min-w-24 rounded-xl bg-gray-200"
                    src={data.data_url}
                  />
                )}
                {data.type === "video" && (
                  <img
                    alt=""
                    className="aspect-square size-24 min-w-24 rounded-xl bg-gray-200"
                    src={data.thumbnail_url}
                  />
                )}
              </div>
            ))}
            {uploadingCount > 0 &&
              [...Array(uploadingCount)].map(count => (
                <div key={count} className="aspect-square size-24 min-w-24 rounded-xl bg-gray-200">
                  <svg className="mr-3 size-5 animate-spin" viewBox="0 0 24 24"></svg>
                </div>
              ))}
          </div>
          {media.length + uploadingCount > 4 && (
            <button
              className="flex items-center rounded-full p-1 shadow"
              onClick={() => {
                scroll("right");
              }}
            >
              <ChevronRightIcon className="size-5 text-gray-500" />
            </button>
          )}
        </div>
      )}
      {!addClick && (
        <div className="ml-auto w-fit">
          <Button
            size={Button.Sizes.Small}
            variant={Button.Variants.Transparent}
            onClick={addMedia}
          >
            {t("main.feedTabs.buttons.addMedia")}
          </Button>
        </div>
      )}
      <input
        type="file"
        className="ml-auto"
        accept="image/*,video/*"
        onChange={e => {
          if (e.target.files && e.target.files.length + uploadingCount + media.length > 10) {
            alert("Max 10 Bilder");
            e.target.value = "";
            return;
          }
          for (const file of e.target.files || []) {
            setUploadingCount(count => count + 1);
            uploadMutation.mutate(file);
          }
          e.target.value = "";
        }}
        multiple={true}
        hidden
        ref={fileInputRef}
      />
    </>
  );
};

export default PostMedia;
