import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { NavLink, useLocation } from "react-router-dom";

import { ListType } from "@src/components/nav/NavSideLinkList";
import { useAppSelector } from "@src/state/hooks";
import useLoginModal from "@src/state/modal/useLoginModal";

type Props = {
  link: ListType;
  depth?: number;
  isMobile?: boolean;
  onClose?: () => void;
  linkIndex: string;
  setLinkIndex: (index: string) => void;
  key?: string;
};

const NavSideLink = ({
  link,
  depth = 0,
  isMobile = false,
  onClose,
  linkIndex = "",
  setLinkIndex,
}: Props) => {
  const location = useLocation();
  const loginModal = useLoginModal();
  const { user } = useAppSelector(state => state.user);

  /**
   * Get component according to type and count
   * @param type number or dot
   * @param count number of notifications
   * @returns component
   */
  const renderNotification = (type: string, count: number) => {
    switch (type) {
      case "number":
        return (
          <div className="flex size-4 items-center justify-center rounded-full bg-tf-red-600 text-mini font-semibold text-white">
            {count > 9 ? "+9" : count}
          </div>
        );

      case "dot":
        return <div className="rounded-full border-[6px] border-tf-red-600 bg-white p-0.5" />;

      default:
        return undefined;
    }
  };

  const checkPath = (url: string, node: string) => {
    const nodeStack = node.split("/").splice(1);
    const urlStack = url.split("/").splice(1);
    const result =
      nodeStack.findIndex((element, index) => {
        return element.replace("/", "") === urlStack[index];
      }) >= 0;
    return result;
  };

  const ChildLinks = () => {
    return (
      <div className="flex w-full flex-col gap-2 border-l-2 border-white border-l-gray-200 max-lg:border max-lg:bg-white">
        {link?.children?.map(childLink => (
          <NavSideLink
            link={childLink}
            depth={depth + 1}
            key={childLink.href}
            isMobile={isMobile}
            onClose={onClose}
            linkIndex=""
            setLinkIndex={() => undefined}
          />
        ))}
      </div>
    );
  };

  return (
    <div
      className={`flex flex-col gap-2 ${link.style}`}
      style={{ margin: "0px 0px 0px " + 20 * depth + "px" }}
    >
      {link.label === linkIndex && link.reverse && <ChildLinks />}
      <div className="flex flex-row items-center justify-between text-base">
        {link.extern ? (
          <a href={link.href} className="flex flex-row items-center gap-2">
            {link.icon} {link.label}
          </a>
        ) : (
          <div className="flex flex-row items-center gap-2 text-nowrap">
            <NavLink
              to={link.href}
              onClick={e => {
                if (!user && link.authRequired) {
                  loginModal.open();
                  e.preventDefault();
                } else {
                  if (isMobile && onClose && link.href.length > 0) onClose();
                  if (link.href.length <= 0) e.preventDefault();
                  if (link.children) {
                    if (link.label === linkIndex) {
                      setLinkIndex("");
                    } else {
                      setLinkIndex(link.label);
                    }
                  }
                }
              }}
            >
              {({ isActive }) => (
                <div className="flex flex-row items-center gap-2">
                  <div
                    className={
                      isActive && checkPath(location.pathname, link.href) ? "scale-[120%]" : ""
                    }
                  >
                    {isActive ? link.solidIcon : link.icon}
                  </div>
                  <div
                    className={
                      isActive && checkPath(location.pathname, link.href) ? "font-semibold" : ""
                    }
                  >
                    {link.label}
                  </div>
                  {link.notification && link.notification?.count > link.notification?.minimum && (
                    <>{renderNotification(link.notification.type, link.notification.count)}</>
                  )}
                </div>
              )}
            </NavLink>
          </div>
        )}
        {link.children && link.children.length > 0 && (
          <ChevronDownIcon
            className={`ml-auto size-4 cursor-pointer ${link.reverse && "rotate-180"}`}
            onClick={() => {
              if (link.label === linkIndex) {
                setLinkIndex("");
              } else {
                setLinkIndex(link.label);
              }
            }}
          />
        )}
      </div>
      {link.label === linkIndex && !link.reverse && <ChildLinks />}
    </div>
  );
};

export default NavSideLink;
