import api, { RequestWithParams, requestParams } from "@src/api/api";
import { PostType } from "@src/types/PostType";

type PostsParams = {
  page?: number;
  q?: string;
  type?: string;
};

type PostsRequest = RequestWithParams<PostsParams> & {
  hashtag: string;
};

export type PostsResponse = {
  data: PostType[];
  current_page: number;
  total_pages: number;
};

export default async function posts(request: PostsRequest): Promise<PostsResponse> {
  const { data } = await api.get(
    `/api/v2/posts${requestParams({ page: 1, ...request.params, hashtag: request.hashtag })}`,
  );
  return data;
}
