import { useEffect } from "react";

import AppPromoPopup from "@src/components/popup/AppPopup";
import usePopupModal from "@src/state/modal/usePopupModal";
import isMobile from "@src/utils/isMobile";

const useAppPromoPopup = () => {
  const popupModal = usePopupModal();

  const onPopupClose = () => {
    popupModal.close();

    setTimeout(() => {
      popupModal.open(<AppPromoPopup onClose={onPopupClose} />);
    }, 30000);
  };

  useEffect(() => {
    if (isMobile) {
      popupModal.open(<AppPromoPopup onClose={onPopupClose} />);
    }
  }, []);
};

export default useAppPromoPopup;
