import { PencilIcon } from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import suggestions from "@src/api/users/suggestions";
import PopularWidget from "@src/components/elements/PopularWidget";
import UserWidget from "@src/components/elements/UserWidget";
import CreatePost from "@src/components/elements/posts/form/CreatePost";
import TabBodies from "@src/components/elements/shared/tab/TabBodies";
import TabBody from "@src/components/elements/shared/tab/TabBody";
import TabController from "@src/components/elements/shared/tab/TabController";
import TabHeader from "@src/components/elements/shared/tab/TabHeader";
import TabHeaders from "@src/components/elements/shared/tab/TabHeaders";
import FollowingFeedContainer from "@src/pages/news/posts/FollowingFeedContainer";
import MyFeedContainer from "@src/pages/news/posts/MyFeedContainer";
import PostsContainer from "@src/pages/news/posts/PostsContainer";
import { useAppSelector } from "@src/state/hooks";
import useLoginModal from "@src/state/modal/useLoginModal";
import useSearch from "@src/state/modal/useSearch";
import isMobile from "@src/utils/isMobile";
import useDebounce from "@src/utils/useDebounce";

type Props = {
  filter?: string;
};

const NewsContainer = ({ filter = "toYou" }: Props) => {
  const { user } = useAppSelector(state => state.user);
  const { t } = useTranslation();
  const search = useSearch();
  const debouncedSearchQuery = useDebounce(search.keywords, 500);
  const navigate = useNavigate();
  const loginModal = useLoginModal();
  const [createFormKey, setCreateFormKey] = useState(0);

  const suggestionsResult = useQuery({
    queryKey: ["users-suggestions"],
    queryFn: () => suggestions(),
  });

  const checkAuth = () => {
    if (!user) {
      loginModal.open();
      return false;
    }
    return true;
  };

  return (
    <div className="relative grid w-full grid-flow-row auto-rows-max grid-cols-1 gap-3">
      <PopularWidget>
        {suggestionsResult.isSuccess &&
          suggestionsResult.data.data.map(user => (
            <div className="w-[70px] shrink-0" key={user.id}>
              <UserWidget key={user.id} user={user} showName={true} />
            </div>
          ))}
      </PopularWidget>

      <TabController defaultTab={filter}>
        <TabHeaders headerCSS="justify-evenly sticky top-0 bg-white z-10 pt-4 bg-opacity-[97%] backdrop-blur-md">
          <TabHeader id="following" url="/news/following">
            {t("main.feedTabs.following")}
          </TabHeader>
          <TabHeader id="toYou" url="/news/für_dich">
            {t("main.feedTabs.toYou")}
          </TabHeader>
        </TabHeaders>
        {!isMobile ? (
          <CreatePost
            hideCloseButton
            // @ts-expect-error A key is required to reset the create form
            key={createFormKey}
            resetForm={() => {
              setCreateFormKey(prevKey => prevKey + 1);
            }}
          />
        ) : (
          <></>
        )}
        <TabBodies>
          <TabBody id="myFeed">
            <MyFeedContainer searchQuery={debouncedSearchQuery} />
          </TabBody>
          <TabBody id="toYou">
            <PostsContainer searchQuery={debouncedSearchQuery} />
          </TabBody>
          <TabBody id="create">{t("main.navLinks.createAd.tabs.create")}</TabBody>
          <TabBody id="following">
            <FollowingFeedContainer searchQuery={debouncedSearchQuery} />
          </TabBody>
          <TabBody id="trending">
            <PostsContainer searchQuery={debouncedSearchQuery} />
          </TabBody>
        </TabBodies>
      </TabController>

      <button
        className="sticky bottom-2 left-full z-20 flex size-[50px] items-center justify-center rounded-full bg-blue-200 lg:hidden"
        onClick={() => checkAuth() && navigate("/news/erstellen")}
      >
        <PencilIcon className="size-6 text-gray-600" />
      </button>
    </div>
  );
};

export default NewsContainer;
