import { useInfiniteQuery } from "@tanstack/react-query";

import birthdays from "@src/api/contacts/birthdays";
import ContactItem from "@src/pages/contacts/ContactItem";
import { UserType } from "@src/types/UserType";

type Props = {
  searchQuery?: string;
};

const BirthdaysList = ({ searchQuery }: Props) => {
  const forYouResult = useInfiniteQuery({
    queryKey: ["contacts", "birthdays", searchQuery],
    queryFn: ({ pageParam }) => birthdays({ params: { page: pageParam, q: searchQuery } }),
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      const nextPage = lastPage.current_page + 1;
      return nextPage <= lastPage.total_pages ? nextPage : null;
    },
  });

  return (
    <>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
        {forYouResult.data?.pages.map(page =>
          page.data.map((item: UserType) => (
            <ContactItem key={item.id} mode="birthdays" user={item} />
          )),
        )}
      </div>
    </>
  );
};

export default BirthdaysList;
