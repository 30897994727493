import { ArrowUturnLeftIcon, ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import job from "@src/api/jobs/job";
import user from "@src/api/users/user";
import Button from "@src/components/elements/input/Button";
import ImageGrid from "@src/components/elements/shop/ImageGrid";
import ScoreTable from "@src/components/elements/shop/ScoreTable";
import StatsHeader from "@src/components/elements/shop/StatsHeader";
import { mediaData } from "@src/data/mediaData";
import JobReviews from "@src/pages/jobs/components/JobReviews";
import MessageFormButton from "@src/pages/jobs/details/actionbar/MessageFormButton";
import LegacyPost from "@src/pages/news/posts/LegacyPost";
import { JobType } from "@src/types/JobType";
import { MediaType } from "@src/types/MediaType";
import getName from "@src/utils/getName";

type Props = {
  preloadedJob?: JobType;
};

const JobsDetails = ({ preloadedJob }: Props) => {
  const { t } = useTranslation();
  const isPreview = preloadedJob !== undefined;
  const { id } = useParams();
  const [textMinimized, setTextMinimized] = useState(true);
  const navigate = useNavigate();
  const jobResult = useQuery({
    queryKey: [`job-${id}`],
    queryFn: () => job({ id: id ?? "" }),
    enabled: !isPreview && !!id,
    initialData: { data: preloadedJob },
  });

  const media: Array<MediaType> = mediaData;

  const userResult = useQuery({
    queryKey: ["user", `user-${jobResult.data.data?.user_id}`],
    queryFn: () => user({ id: jobResult.data.data?.user_id ?? "" }),
    retry: false,
  });

  const jobData = isPreview ? preloadedJob : jobResult.data.data;
  if (!jobResult.isSuccess || !userResult.isSuccess || !jobData) return <div>Loading...</div>;

  return (
    <div className={`${!isPreview && "xl:px-[180px]"}`}>
      <div className="flex flex-row items-start justify-between max-xl:px-4">
        <div className="text-xl">{jobData.title}</div>
        {!isPreview && (
          <ArrowUturnLeftIcon
            className="size-6 cursor-pointer"
            onClick={() => {
              navigate(-1);
            }}
          />
        )}
      </div>
      <StatsHeader />

      <div className="flex flex-col gap-4 divide-y divide-black text-darkblue">
        <div className="relative flex h-fit flex-col gap-4 xl:flex-row">
          <div className="left-0 top-0 flex h-fit shrink-0 flex-col gap-2 xl:sticky xl:w-2/5">
            <ImageGrid
              images={media}
              likeButton={() => {
                return;
              }}
              shareButton={() => {
                return;
              }}
            />
          </div>
          <div className="flex w-full flex-col justify-between gap-2 p-4 text-sm max-xl:px-4 xl:flex-row">
            <div className="flex flex-col">
              <div className="text-sm font-semibold">
                {t("main.navLinks.jobsPublished.description")}:
              </div>
              <div className={`text-sm ${textMinimized && "line-clamp-5"}`}>
                {jobData.old_id ? (
                  <LegacyPost content={jobData.description} />
                ) : (
                  jobData.description
                )}
              </div>
              <button
                className="mt-1 flex w-36 items-center justify-between rounded-full border border-gray-500 px-2 py-1 text-xs"
                onClick={() => setTextMinimized(!textMinimized)}
              >
                {textMinimized ? t("buttons.showMore") : t("buttons.showLess")}
                <div className="shrink-0">
                  {textMinimized ? (
                    <ChevronDownIcon className="size-4" />
                  ) : (
                    <ChevronUpIcon className="size-4" />
                  )}
                </div>
              </button>
            </div>
          </div>

          <div className="flex shrink-0 flex-col gap-8 p-4 text-gray-800 md:p-0 xl:w-[250px]">
            <div className="flex h-fit flex-col rounded-lg border border-gray-200 bg-gray-100 px-2 py-1 text-sm text-gray-800">
              <div className="mb-1.5 whitespace-nowrap border-b border-gray-300 pb-1.5 font-semibold">
                {t("main.navLinks.jobsPublished.general")}
              </div>
              <div className="grid max-w-full items-center">
                <div className="flex">
                  <img
                    src={"/images/icons/briefcase.svg"}
                    className="mr-1 mt-1 flex size-[15px]"
                    alt="icon"
                  />
                  <div className="font-bold">{t("main.navLinks.jobsPublished.occupation")}:</div>
                </div>

                <div className="ml-5">{jobData.occupation}</div>
              </div>

              <div className="grid max-w-full grid-cols-[20px,1fr] items-center">
                <div className="">
                  <img
                    src={"/images/icons/calender-days.svg"}
                    className="mr-1 flex size-[15px]"
                    alt="icon"
                  />
                </div>

                <div className="font-bold">{t("main.navLinks.jobsPublished.jobStartDate")}:</div>
                <div className="ml-5">
                  {jobData.start_date
                    ? new Date(jobData.start_date).toLocaleDateString("de-DE")
                    : ""}
                </div>
              </div>

              <div className="grid max-w-full grid-cols-[20px,1fr] items-center">
                <div className="">
                  <img
                    src={"/images/icons/circle-badge.svg"}
                    className="mr-1 flex size-[15px]"
                    alt="icon"
                  />
                </div>
                <div className="font-bold">
                  {t("main.navLinks.jobsPublished.jobSocialSecurityRequired")}:
                </div>
                <div></div>
                {jobData.social_security_required ? "ja" : "nein"}
              </div>
              <div>
                <div className="grid max-w-full grid-cols-[20px,1fr] items-center">
                  <img
                    src={"/images/icons/folder.svg"}
                    className="mr-1 flex size-[15px]"
                    alt="icon"
                  />
                  <div className="font-bold">
                    {t("main.navLinks.jobsPublished.applicationDetails")}:
                  </div>
                  <div className=""></div>
                </div>
                <div className="grid max-w-full items-center">
                  <div className="flex">
                    <img
                      src={"/images/icons/map-pin.svg"}
                      className="mr-1 mt-1 flex size-[15px]"
                      alt="icon"
                    />
                    <div className="font-bold">
                      {" "}
                      {t("main.navLinks.jobsPublished.typeOfApplication")}:
                    </div>
                  </div>

                  <div className="ml-5">{jobData.application_type}</div>
                </div>
              </div>
            </div>
            <div className="flex h-fit flex-col rounded-lg border border-gray-200 bg-gray-100 px-2 py-1 text-sm text-gray-800">
              <div className="mb-1.5 whitespace-nowrap border-b border-gray-300 pb-1.5 font-semibold">
                {t("main.navLinks.jobsPublished.contactInformation")}
              </div>
              <div className="grid max-w-full grid-cols-[20px,1fr] items-center">
                <img src={"/images/icons/clock.svg"} className="mr-1 flex size-[15px]" alt="icon" />
                <div className="font-bold">{t("main.navLinks.jobsPublished.workingHours")}:</div>
                <div className="ml-5">
                  {jobData.weekly_hours}
                  <span className="ml-1">
                    {t("main.navLinks.jobsPublished.jobDataWeeklyHours")}
                  </span>
                </div>
              </div>

              <div className="grid max-w-full grid-cols-[20px,1fr] items-center">
                <img
                  src={"/images/icons/currency-euro.svg"}
                  className="mr-1 flex size-[15px]"
                  alt="icon"
                />
                <div className="font-bold">{t("main.navLinks.jobsPublished.remuneration")}: </div>
                <div className="ml-5"> {jobData.compensation}</div>
              </div>

              <div className="grid max-w-full grid-cols-[20px,1fr] items-center">
                <img
                  src={"/images/icons/clipboard-document-list.svg"}
                  className="mr-1 flex size-[15px]"
                  alt="icon"
                />{" "}
                <div className="font-bold">{t("main.navLinks.jobsPublished.wageAgreement")}:</div>
                <div className="ml-5">{jobData.standard_wage ? "ja" : "nein"}</div>
              </div>

              <div className="grid max-w-full grid-cols-[20px,1fr] items-center">
                <img
                  src={"/images/icons/chart-bar.svg"}
                  className="mr-1 flex size-[15px]"
                  alt="icon"
                />
                <div className="font-bold">{t("main.navLinks.jobsPublished.limitation")}:</div>
                <div className="ml-5">{jobData.limitation ? "ja" : "nein"}</div>
              </div>

              <div className="grid max-w-full items-center">
                <div className="flex">
                  <img
                    src={"/images/icons/map-pin.svg"}
                    className="mr-1 mt-1 flex size-[15px]"
                    alt="icon"
                  />
                  <div className="font-bold">{t("main.navLinks.jobsPublished.location")}:</div>
                </div>

                <div className="ml-5">{jobData.working_place}</div>
              </div>
            </div>
            <Button variant={Button.Variants.Transparent} size={Button.Sizes.Small}>
              <MessageFormButton />
            </Button>
          </div>
        </div>
        <div className="pt-2 max-xl:px-4">
          <div className="flex h-fit flex-col rounded-lg border border-gray-200 px-2 py-1 text-sm">
            <div className="mb-1.5 whitespace-nowrap border-b border-gray-300 pb-1.5 font-semibold">
              {t("main.navLinks.jobsPublished.contactInformation")}
            </div>
            <div className="flex flex-wrap gap-1">
              <div>{getName(userResult.data.data) ?? jobData.company}</div>
            </div>

            <div className="flex flex-wrap gap-1">{userResult.data.data.email}</div>
          </div>
        </div>
        <div className="flex flex-col gap-2 p-4">
          <div className="flex flex-col gap-8 xl:flex-row">
            <ScoreTable />
            <div className="w-full">
              <JobReviews />
              <div className="text-right text-sm font-semibold text-lightblue hover:underline">
                <NavLink to={"/jobs/reviews/" + id}>
                  {t("main.navLinks.jobsPublished.seeMoreReviews")}
                </NavLink>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-5 flex flex-wrap justify-end gap-4 pt-2 font-normal max-xl:px-4">
          <Button
            onClick={() => {
              console.log("Bewertungen");
            }}
            variant={Button.Variants.Transparent}
            size={Button.Sizes.Small}
            fitWidth
          >
            {t("main.navLinks.jobsPublished.multipleReviews")}
          </Button>
          <Button
            onClick={() => {
              console.log("Veröffentlichen");
            }}
            variant={Button.Variants.Transparent}
            size={Button.Sizes.Small}
            fitWidth
          >
            {t("buttons.publish")}
          </Button>
          <Button variant={Button.Variants.Transparent} size={Button.Sizes.Small} fitWidth>
            <NavLink to={`/profile/${jobData?.user_id}`} target="_blank">
              {t("main.navLinks.jobsPublished.toEmployee")}
            </NavLink>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default JobsDetails;
