import api, { RequestWithParams, requestParams } from "@src/api/api";
import { UserType } from "@src/types/UserType";

type ForYouParams = {
  type?: string | null;
  page?: number;
  q?: string | null;
};

type ForYouRequest = RequestWithParams<ForYouParams>;

type ForYouResponse = {
  data: UserType[];
  current_page: number;
  total_pages: number;
};

export default async function forYou(request: ForYouRequest): Promise<ForYouResponse> {
  const { data } = await api.get(
    `/api/v1/contacts${requestParams({ page: 1, ...request.params })}`,
  );
  return data;
}
