import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import BackButton from "@src/components/nav/BackButton";

const PrivacyContainer = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="pl-4 pt-4">
        <BackButton stepback desktopVisible />
      </div>
      <div className="flex flex-col gap-3 pb-4 text-justify text-xs text-gray-700">
        <h1 className="text-left text-smbase font-bold">
          {t("pages.moreContainer.privacyPolicy")}
        </h1>
        <h2 className="font-semibold">{t("pages.privacyContainer.text1")}</h2>
        <div>{t("pages.privacyContainer.text2")}</div>
        <div>
          {t("pages.privacyContainer.text3.1")}
          <NavLink to="/cookies" className="text-darkcyan">
            {t("pages.privacyContainer.text3.2")}
          </NavLink>
          {t("pages.privacyContainer.text3.3")}
          <NavLink to="/cookies" className="text-darkcyan">
            {t("pages.privacyContainer.cookiepolicy")}
          </NavLink>
          {t("pages.privacyContainer.text3.4")}
          <NavLink to="/help" className="text-darkcyan">
            {t("pages.privacyContainer.text3.5")}
          </NavLink>
          {t("pages.privacyContainer.text3.6")}
        </div>
        <h2 className="font-semibold">{t("pages.privacyContainer.text4")}</h2>
        <ul className="list-inside list-decimal">
          <li>{t("pages.privacyContainer.text5.1")}</li>
          <li>{t("pages.privacyContainer.text5.2")}</li>
          <li>{t("pages.privacyContainer.text5.3")}</li>
          <li>{t("pages.privacyContainer.text5.4")}</li>
          <li>{t("pages.privacyContainer.text5.5")}</li>
          <li>{t("pages.privacyContainer.text5.6")}</li>
        </ul>
        <h2 className="font-semibold">1. {t("pages.privacyContainer.text6.1")}</h2>
        <div>{t("pages.privacyContainer.text6.2")}</div>
        <div className="font-medium">{t("pages.privacyContainer.text6.3")}</div>
        <ul className="list-inside list-disc">
          <li>{t("pages.privacyContainer.text6.4")}</li>
          <li>{t("pages.privacyContainer.text6.5")}</li>
        </ul>
        <div>{t("pages.privacyContainer.text6.6")}</div>
        <h2 className="font-semibold">2. {t("pages.privacyContainer.text7.1")}</h2>
        <div>{t("pages.privacyContainer.text7.2")}</div>
        <div className="font-semibold">2.1 {t("pages.privacyContainer.text7.3")}</div>
        <ul className="list-inside list-disc">
          <li>{t("pages.privacyContainer.text7.4")}</li>
          <li>{t("pages.privacyContainer.text7.5")}</li>
          <li>{t("pages.privacyContainer.text7.6")}</li>
        </ul>
        <div className="font-semibold">2.2 {t("pages.privacyContainer.text7.7")}</div>
        <ul className="list-inside list-disc">
          <li>{t("pages.privacyContainer.text7.8")}</li>
          <li>{t("pages.privacyContainer.text7.9")}</li>
        </ul>
        <div className="font-semibold">2.3 {t("pages.privacyContainer.text7.10")}</div>
        <ul className="list-inside list-disc">
          <li>{t("pages.privacyContainer.text7.11")}</li>
          <li>
            {t("pages.privacyContainer.text7.12.a")}
            <NavLink to="/cookies" className="text-darkcyan">
              {t("pages.privacyContainer.cookiepolicy")}
            </NavLink>
            {t("pages.privacyContainer.text7.12.b")}
          </li>
          <li>{t("pages.privacyContainer.text7.13")}</li>
        </ul>
        <h2 className="font-semibold">3. {t("pages.privacyContainer.text8.1")}</h2>
        <div>{t("pages.privacyContainer.text8.2")}</div>
        <div className="font-semibold">3.1 {t("pages.privacyContainer.text8.3")}</div>
        <ul className="list-inside list-disc">
          <li>{t("pages.privacyContainer.text8.4")}</li>
          <li>{t("pages.privacyContainer.text8.5")}</li>
          <li>{t("pages.privacyContainer.text8.6")}</li>
        </ul>
        <div className="font-semibold">3.2 {t("pages.privacyContainer.text8.7")}</div>
        <ul className="list-inside list-disc">
          <li>{t("pages.privacyContainer.text8.8")}</li>
          <li>{t("pages.privacyContainer.text8.9")}</li>
          <li>{t("pages.privacyContainer.text8.10")}</li>
        </ul>
        <div className="font-semibold">3.3 {t("pages.privacyContainer.text8.11")}</div>
        <ul className="list-inside list-disc">
          <li>{t("pages.privacyContainer.text8.12")}</li>
          <li>{t("pages.privacyContainer.text8.13")}</li>
          <li>{t("pages.privacyContainer.text8.14")}</li>
        </ul>
        <h2 className="font-semibold">4. {t("pages.privacyContainer.text9.1")}</h2>
        <div>{t("pages.privacyContainer.text9.2")}</div>
        <div className="font-semibold">4.1 {t("pages.privacyContainer.text9.3")}</div>
        <div>{t("pages.privacyContainer.text9.4")}</div>
        <ul className="list-inside list-disc">
          <li>{t("pages.privacyContainer.text9.5")}</li>
          <li>{t("pages.privacyContainer.text9.6")}</li>
          <li>{t("pages.privacyContainer.text9.7")}</li>
          <li>{t("pages.privacyContainer.text9.8")}</li>
        </ul>
        <div className="font-semibold">4.2 {t("pages.privacyContainer.text9.9")}</div>
        <div>{t("pages.privacyContainer.text9.10")}</div>
        <ul className="list-inside list-disc">
          <li>{t("pages.privacyContainer.text9.11")}</li>
          <li>{t("pages.privacyContainer.text9.12")}</li>
          <li>{t("pages.privacyContainer.text9.13")}</li>
          <li>{t("pages.privacyContainer.text9.14")}</li>
        </ul>
        <div>{t("pages.privacyContainer.text9.15")}</div>
        <ul className="list-inside list-disc">
          <li>{t("pages.privacyContainer.text9.16")}</li>
          <li>{t("pages.privacyContainer.text9.17")}</li>
        </ul>
        <div>{t("pages.privacyContainer.text9.18")}</div>
        <div className="font-semibold">4.3 {t("pages.privacyContainer.text9.19")}</div>
        <ul className="list-inside list-disc">
          <li>{t("pages.privacyContainer.text9.20")}</li>
          <li>{t("pages.privacyContainer.text9.21")}</li>
        </ul>
        <h2 className="font-semibold">5. {t("pages.privacyContainer.text10.1")}</h2>
        <div>{t("pages.privacyContainer.text10.2")}</div>
        <div className="font-semibold">5.1 {t("pages.privacyContainer.text10.3")}</div>
        <ul className="list-inside list-disc">
          <li>{t("pages.privacyContainer.text10.4")}</li>
          <li>{t("pages.privacyContainer.text10.5")}</li>
        </ul>
        <div className="font-semibold">5.2 {t("pages.privacyContainer.text10.6")}</div>
        <ul className="list-inside list-disc">
          <li>{t("pages.privacyContainer.text10.7")}</li>
          <li>{t("pages.privacyContainer.text10.8")}</li>
        </ul>
        <div className="font-semibold">5.3 {t("pages.privacyContainer.text10.9")}</div>
        <ul className="list-inside list-disc">
          <li>{t("pages.privacyContainer.text10.10")}</li>
          <li>{t("pages.privacyContainer.text10.11")}</li>
        </ul>
        <h2 className="font-semibold">6. {t("pages.privacyContainer.text11.1")}</h2>
        <div className="font-semibold">6.1 {t("pages.privacyContainer.text11.2")}</div>
        <div>{t("pages.privacyContainer.text11.3")}</div>
        <ul className="list-inside list-disc">
          <li>{t("pages.privacyContainer.text11.4")}</li>
          <li>{t("pages.privacyContainer.text11.5")}</li>
          <li>{t("pages.privacyContainer.text11.6")}</li>
        </ul>
        <div className="font-semibold">6.2 {t("pages.privacyContainer.text11.7")}</div>
        <ul className="list-inside list-disc">
          <li>{t("pages.privacyContainer.text11.8")}</li>
        </ul>
        <div className="font-semibold">6.3 {t("pages.privacyContainer.text11.9")}</div>
        <div>{t("pages.privacyContainer.text11.10")}</div>
        <h2 className="font-semibold">{t("pages.privacyContainer.text12.1")}</h2>
        <div>{t("pages.privacyContainer.text12.2")}</div>
        <div>
          <div className="text-xs font-bold">
            {t("main.profileViewTabs.aboutMe.content.furtherInfo")}
          </div>
          <div className="flex flex-col gap-0.5 text-xs">
            <NavLink to="/datenschutz" className="text-darkcyan">
              {t("pages.conditionContainer.text14")}
            </NavLink>
            <NavLink to="/urheberrecht" className="text-darkcyan">
              {t("pages.conditionContainer.text15")}
            </NavLink>
            <NavLink to="/cookies" className="text-darkcyan">
              {t("pages.conditionContainer.text16")}
            </NavLink>
            <NavLink to="/community" className="text-darkcyan">
              {t("pages.comRulesContainer.text1")}
            </NavLink>
            <NavLink to="/nutzungsbedingungen" className="text-darkcyan">
              {t("pages.conditionContainer.text17")}
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyContainer;
