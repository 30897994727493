import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { OrderedList } from "@src/components/elements/shared/ListComponents";
import BackButton from "@src/components/nav/BackButton";

const ConditionsContainer = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="pl-4 pt-4">
        <BackButton stepback desktopVisible />
      </div>
      <div className="flex flex-col gap-3 pb-4 text-justify text-xs text-gray-700">
        <div className="text-left text-smbase font-bold">
          {t("pages.conditionContainer.header")}
        </div>
        <div className="font-semibold">{t("pages.conditionContainer.title01")}</div>
        <div>{t("pages.conditionContainer.text01")}</div>
        <div className="font-semibold">{t("pages.conditionContainer.title02")}</div>
        <div>{t("pages.conditionContainer.text02")}</div>
        <div className="font-semibold">{t("pages.conditionContainer.title03")}</div>
        <div>{t("pages.conditionContainer.text03")}</div>
        <div className="font-semibold">{t("pages.conditionContainer.title04")}</div>
        <OrderedList
          items={[
            t("pages.conditionContainer.text04.1"),
            t("pages.conditionContainer.text04.2"),
            t("pages.conditionContainer.text04.3"),
            t("pages.conditionContainer.text04.4"),
          ]}
        />
        <div className="font-semibold">{t("pages.conditionContainer.title05")}</div>
        <OrderedList
          items={[t("pages.conditionContainer.text05.1"), t("pages.conditionContainer.text05.2")]}
        />
        <div className="font-semibold">{t("pages.conditionContainer.title06")}</div>
        <OrderedList
          items={[t("pages.conditionContainer.text06.1"), t("pages.conditionContainer.text06.2")]}
        />
        <div className="font-semibold">{t("pages.conditionContainer.title07")}</div>
        <OrderedList
          items={[
            t("pages.conditionContainer.text07.1"),
            t("pages.conditionContainer.text07.2"),
            t("pages.conditionContainer.text07.3"),
          ]}
        />
        <div className="font-semibold">{t("pages.conditionContainer.title08")}</div>
        <OrderedList
          items={[t("pages.conditionContainer.text08.1"), t("pages.conditionContainer.text08.2")]}
        />
        <div className="font-semibold">{t("pages.conditionContainer.title09")}</div>
        <OrderedList
          items={[
            t("pages.conditionContainer.text09.1"),
            t("pages.conditionContainer.text09.2"),
            t("pages.conditionContainer.text09.3"),
          ]}
        />
        <div>{t("pages.conditionContainer.text11")}</div>
        <div className="font-semibold">day one mediagroup GmbH & Co. KG</div>
        <div className="flex flex-col">
          <div>August-Bebel-Straße 11/13</div>
          <div>09113 Chemnitz</div>
          <div>Deutschland</div>
        </div>
        <div className="flex flex-col">
          <div>Telefon: +49 (0) 371 52 49 99 19 00</div>
          <div>Telefax: +49 (0) 371 91 89 56 11</div>
          <div>E-Mail: info@tradefoox.com</div>
        </div>
        <div className="inline-flex gap-1">
          <div className="font-semibold">USt-IdNr.:</div>
          DE 304342652
        </div>
        <div className="flex flex-col">
          <div className="inline-flex gap-1">
            <div className="font-semibold">{t("pages.conditionContainer.text12")}</div>
            Ronny Streit, Nico Seifert
          </div>
        </div>
        <div>{t("pages.conditionContainer.header")}</div>
        <div>{t("pages.conditionContainer.text10")}</div>
        <div>
          <div className="font-semibold">{t("pages.conditionContainer.text13")}</div>
          <div className="flex flex-col gap-0.5">
            <NavLink to="/datenschutz" className="text-darkcyan">
              {t("pages.conditionContainer.text14")}
            </NavLink>
            <NavLink to="/urheberrecht" className="text-darkcyan">
              {t("pages.conditionContainer.text15")}
            </NavLink>
            <NavLink to="/cookies" className="text-darkcyan">
              {t("pages.conditionContainer.text16")}
            </NavLink>
            <NavLink to="/community" className="text-darkcyan">
              {t("pages.comRulesContainer.text1")}
            </NavLink>
            <NavLink to="/nutzungsbedingungen" className="text-darkcyan">
              {t("pages.conditionContainer.text17")}
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConditionsContainer;
