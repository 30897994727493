import { useQuery } from "@tanstack/react-query";
import { RefObject, useRef } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import myVisitors from "@src/api/contacts/myVisitors";
import getUser from "@src/api/users/user";
import { useInfiniteQueryOnScroll } from "@src/hooks/useInfiniteQueryOnScroll";
import useUserBubbleModal from "@src/state/modal/useUserBubbleModal";
import { ProfileVisitor } from "@src/types/ProfileVisitorType";
import { UserType } from "@src/types/UserType";
import { formatDate } from "@src/utils/formatDate";

type Props = {
  searchQuery?: string;
};

const useUserDetails = (userId: string) => {
  return useQuery({
    queryKey: ["user", `user-${userId}`],
    queryFn: () => getUser({ id: userId }),
    retry: false,
  });
};

const ContactMyVisitorsContainer = ({ searchQuery }: Props) => {
  const { t } = useTranslation();
  const userBubbleModal = useUserBubbleModal();

  const { result: myVisitorsResult } = useInfiniteQueryOnScroll({
    queryKey: ["contacts", "my-followers", searchQuery],
    queryFn: async ({ pageParam }) => myVisitors({ params: { page: pageParam, q: searchQuery } }),
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      const nextPage = lastPage.current_page + 1;
      return nextPage <= lastPage.total_pages ? nextPage : null;
    },
  });

  const handleMouseOver = (ref: RefObject<HTMLAnchorElement>, userData: UserType | undefined) => {
    if (ref.current && userData) {
      const rect = ref.current.getBoundingClientRect();
      userBubbleModal.open({
        position: { x: rect.x, y: rect.y },
        user: userData,
      });
    }
  };

  const handleMouseLeave = () => {
    userBubbleModal.close();
  };

  const VisitorComponent = ({ key, visitor }: { key: string; visitor: ProfileVisitor }) => {
    const placeholderImage = `/images/placeholder/${visitor.user_type}.png`;
    const containerRef = useRef<HTMLAnchorElement>(null);

    const { data: userResult, isLoading } = useUserDetails(visitor.visitor_id);

    return (
      <div className="relative" onMouseLeave={handleMouseLeave}>
        <NavLink
          to={`/profile/${visitor.visitor_id}`}
          className="group"
          ref={containerRef}
          onMouseOver={() => !isLoading && handleMouseOver(containerRef, userResult?.data)}
        >
          <li className="mb-4 flex items-center" key={key}>
            <img
              src={visitor.avatar_link || placeholderImage}
              alt={visitor.name}
              className="bottom-1 mr-4 size-12 rounded-full border"
            />
            <div>
              <p className="m-0 font-bold">{visitor.name}</p>
              <p className="text-sm">
                {t("main.contacts.statistics.visitedOn")}:{" "}
                {formatDate(new Date(visitor.visit_date).toISOString(), true)}
              </p>
            </div>
          </li>
        </NavLink>
      </div>
    );
  };

  return (
    <div>
      <ul className="list-none p-0">
        {myVisitorsResult.data?.pages.map(page =>
          page.data.map((item: ProfileVisitor) => (
            <VisitorComponent key={item.visitor_id} visitor={item} />
          )),
        )}
      </ul>
    </div>
  );
};

export default ContactMyVisitorsContainer;
