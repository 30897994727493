import { InfiniteData, UseInfiniteQueryResult } from "@tanstack/react-query";

import { PostsResponse } from "@src/api/hashtags/posts";

const nextPost = ({
  index,
  pageIndex,
  postsResult,
}: {
  index: number;
  pageIndex: number;
  postsResult: UseInfiniteQueryResult<InfiniteData<PostsResponse>>;
}) => {
  if (postsResult.data !== undefined) {
    if (
      pageIndex + 1 < postsResult.data.pages.length &&
      index + 1 == postsResult.data.pages[pageIndex]?.data.length
    )
      return postsResult.data.pages[pageIndex + 1]?.data[0]?.id;
    if (index + 1 < postsResult.data.pages[pageIndex]?.data.length)
      return postsResult.data.pages[pageIndex]?.data[index + 1]?.id;
  }
  return "";
};

export default nextPost;
