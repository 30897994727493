import { BookmarkIcon } from "@heroicons/react/24/solid";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

import bookmark from "@src/api/posts/bookmark";
import remove from "@src/api/posts/bookmark/remove";
import { useAppSelector } from "@src/state/hooks";
import useLoginModal from "@src/state/modal/useLoginModal";
import { PostType } from "@src/types/PostType";

type Props = {
  data: PostType;
  postId: string;
  queryKey?: (string | null)[];
};

const WatchBookmarkButton = ({ postId, queryKey = [""], data }: Props) => {
  const [actionEffect, setActionEffect] = useState(false);
  const queryClient = useQueryClient();
  const { user } = useAppSelector(state => state.user);
  const loginModal = useLoginModal();

  const bookmarkMutation = useMutation({
    mutationKey: ["bookmark"],
    mutationFn: async (id: string) => {
      const isBookmarked = data.bookmarked;

      await queryClient.cancelQueries({ queryKey });
      const previousData = queryClient.getQueryData<{ pages: Array<{ data: PostType[] }> }>(
        queryKey,
      );

      if (!previousData) return;
      for (const page of previousData.pages) {
        for (const post of page.data) {
          if (post.id === data.id) {
            if (data.bookmarked) {
              post.bookmark_count -= 1;
            } else {
              post.bookmark_count += 1;
            }
            post.bookmarked = !data.bookmarked;
            break;
          }
        }
      }
      queryClient.setQueryData(queryKey, previousData);

      if (isBookmarked) {
        return await remove({ id });
      }
      return await bookmark({ id });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["posts", "watch"] });
    },
  });

  return (
    <button
      onClick={e => {
        if (user) {
          if (!data.bookmarked) setActionEffect(true);
          bookmarkMutation.mutate(postId);
        } else {
          loginModal.open();
        }
        e.stopPropagation();
      }}
      className={`size-8 transition-all duration-200 ease-out ${data.bookmarked && "text-yellow-400"}`}
    >
      <BookmarkIcon
        className={`${actionEffect && "animate-ping-once"}`}
        onAnimationEnd={() => {
          setActionEffect(false);
        }}
      />
    </button>
  );
};

export default WatchBookmarkButton;
