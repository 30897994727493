import { useState } from "react";
import { useTranslation } from "react-i18next";

import following from "@src/api/users/following";
import follows from "@src/api/users/follows";
import ButtonPanel from "@src/components/box/ButtonPanel";
import Input from "@src/components/elements/input/Input";
import { useInfiniteQueryOnScroll } from "@src/hooks/useInfiniteQueryOnScroll";
import ContactItem from "@src/pages/contacts/ContactItem";
import { UserType } from "@src/types/UserType";
import useDebounce from "@src/utils/useDebounce";

type Props = {
  type: "following" | "follows";
  userId: string;
  onClose: () => void;
};

const UserRelationList = (props: Props) => {
  const { t } = useTranslation();
  const { userId } = props;
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const { result } = useInfiniteQueryOnScroll({
    queryKey: [props.type, userId, debouncedSearchQuery],
    queryFn: ({ pageParam }) =>
      props.type === "following"
        ? following({
            id: userId,
            params: { page: pageParam, q: debouncedSearchQuery },
          })
        : follows({ id: userId, params: { page: pageParam, q: debouncedSearchQuery } }),
    initialPageParam: 0,
    getNextPageParam: lastPage => {
      const nextPage = lastPage.current_page + 1;
      return nextPage <= lastPage.total_pages ? nextPage : null;
    },
  });

  const headlines: { [index: string]: string } = {
    following: t("main.following.title"),
    follows: t("main.followers.title"),
  };

  return (
    <ButtonPanel onClose={props.onClose} title={headlines[props.type]}>
      <Input
        name="search-contacts"
        placeholder={t("main.feedTabs.placeholder")}
        onChange={e => setSearchQuery(e)}
        clearable
      />
      <div className="mt-2 max-h-[80vh]">
        <div className="h-7" />
        <div className="mt-2 grid grid-cols-2 gap-4 xl:grid-cols-5">
          {result.data?.pages.map(page =>
            page.data.map((item: UserType) => (
              <ContactItem key={item.id} mode="default" user={item} />
            )),
          )}
        </div>
      </div>
    </ButtonPanel>
  );
};

export default UserRelationList;
