import api, { RequestWithParams, requestParams } from "@src/api/api";
import { HashtagType } from "@src/types/HashtagType";

type HashtagsParams = {
  page?: number;
  user_id?: string | null;
  watch?: boolean;
  trending?: boolean;
};

type HashtagsRequest = RequestWithParams<HashtagsParams>;

type HashtagsResponse = {
  data?: HashtagType[];
  current_page: number;
  total_pages: number;
};

export default async function hashtags(request: HashtagsRequest): Promise<HashtagsResponse> {
  const { data } = await api.get(
    `/api/v1/hashtags${requestParams({ page: 0, ...request.params })}`,
  );
  return data;
}
