import bookmarks from "@src/api/posts/bookmarks";
import CompactPost from "@src/components/elements/CompactPost";
import { useInfiniteQueryOnScroll } from "@src/hooks/useInfiniteQueryOnScroll";
import { useAppSelector } from "@src/state/hooks";

type Props = {
  limit?: number;
  columnLimit?: number;
};

const News = ({ limit, columnLimit = 5 }: Props) => {
  const { user } = useAppSelector(state => state.user);

  const { result: bookmarksResult } = useInfiniteQueryOnScroll({
    queryKey: ["posts", "bookmarks", "tab"],
    queryFn: ({ pageParam }) => bookmarks({ params: { page: pageParam } }),
    initialPageParam: 0,
    getNextPageParam: lastPage => {
      const nextPage = lastPage.current_page + 1;
      return nextPage <= lastPage.total_pages ? nextPage : null;
    },
    enabled: !!user,
  });

  if (!bookmarksResult.isSuccess) return <></>;

  return (
    <div className="grid grid-flow-row gap-2">
      <div
        className={`grid grid-cols-3 gap-3 ${columnLimit === 3 ? "lg:grid-cols-3" : "xl:grid-cols-4 2xl:grid-cols-5"}`}
      >
        {bookmarksResult.data.pages
          .flatMap(page => page.data)
          .filter(bookmark => bookmark !== null)
          .slice(0, limit)
          .map(bookmark => (
            <CompactPost
              key={`bookmark:${bookmark.id}`}
              link={`/news/für_dich?featured=${bookmark.id}`}
              type="image"
              url={
                bookmark.picture
                  ? bookmark.picture.replace("height=184", "height=600").replace("/tile", "/post")
                  : "/placeholder.png"
              }
              viewCount={bookmark.view_count}
              likeCount={bookmark.like_count}
            />
          ))}
      </div>
    </div>
  );
};

export default News;
