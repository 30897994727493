import { useInfiniteQuery } from "@tanstack/react-query";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import hashtags from "@src/api/hashtags/hashtags";
import Panel from "@src/components/box/Panel";
import Trendsitem from "@src/components/elements/hashtags/Trendsitem";

type Props = { key?: string };

const Trends: FC<Props> = () => {
  const { t } = useTranslation();

  const watchHashtagsResult = useInfiniteQuery({
    queryKey: ["hashtags", "watch", "trending"],
    queryFn: ({ pageParam }) =>
      hashtags({ params: { page: pageParam, watch: true, trending: true } }),
    initialPageParam: 0,
    getNextPageParam: lastPage => {
      const nextPage = lastPage.current_page + 1;
      return nextPage <= lastPage.total_pages ? nextPage : null;
    },
  });

  const feedHashtagsResult = useInfiniteQuery({
    queryKey: ["hashtags", "feed", "trending"],
    queryFn: ({ pageParam }) =>
      hashtags({ params: { page: pageParam, watch: false, trending: true } }),
    initialPageParam: 0,
    getNextPageParam: lastPage => {
      const nextPage = lastPage.current_page + 1;
      return nextPage <= lastPage.total_pages ? nextPage : null;
    },
  });

  if (!watchHashtagsResult.isSuccess && !feedHashtagsResult.isSuccess) return <></>;

  return (
    <Panel>
      <div className="grid grid-cols-1 gap-1">
        {watchHashtagsResult.isSuccess && (
          <>
            <h2 className="text-base font-semibold">
              {t("main.watchTabs.trendingVideoTag", {
                count: (watchHashtagsResult.data?.pages[0].data || []).length,
              })}
            </h2>
            <hr />
            <div className="flex flex-col gap-1">
              {watchHashtagsResult.data.pages
                .flatMap(page => page.data)
                .filter(hashtag => !!hashtag)
                .map((hashtag, index) => (
                  <Trendsitem
                    index={index}
                    tab="watch"
                    key={hashtag.hashtag + index}
                    tag={hashtag.hashtag}
                    type={hashtag.media_type}
                    viewCount={hashtag.total_views}
                    likeCount={hashtag.total_like_count}
                    authorName={hashtag.author_name}
                    authorAvatar={hashtag.author_avatar}
                  />
                ))}
            </div>
          </>
        )}

        {feedHashtagsResult.isSuccess && (
          <>
            <h2 className="text-base font-semibold">
              {t("main.watchTabs.trendingPostTag", {
                count: (feedHashtagsResult.data?.pages[0].data || []).length,
              })}
            </h2>
            <hr />
            <div className="flex flex-col gap-1">
              {feedHashtagsResult.data.pages
                .flatMap(page => page.data)
                .filter(hashtag => !!hashtag)
                .map((hashtag, index) => (
                  <Trendsitem
                    index={index}
                    tab="feed"
                    key={hashtag.hashtag + index}
                    tag={hashtag.hashtag}
                    type={hashtag.media_type}
                    viewCount={hashtag.total_views}
                    likeCount={hashtag.total_like_count}
                    authorName={hashtag.author_name}
                    authorAvatar={hashtag.author_avatar}
                  />
                ))}
            </div>
            <NavLink
              to={"entdecken/hashtags/feed"}
              className="mt-3 w-full rounded-full bg-gray-200 py-1 text-center text-xs hover:bg-gray-300"
            >
              {t("buttons.showMore")}
            </NavLink>
          </>
        )}
      </div>
    </Panel>
  );
};

export default Trends;
