import { useQuery } from "@tanstack/react-query";
import moment from "moment";

import messages from "@src/api/chats/messages";
import MessengerBubble from "@src/pages/messenger/MessengerBubble";
import { MediaType } from "@src/types/MediaType";
import { UserType } from "@src/types/UserType";

type Props = {
  chatId: string;
  me: UserType;
  query?: string;
  onForward: (data: { text: string; link: string | undefined; media: Array<MediaType> }) => void;
  isGroup?: boolean;
};

const MessagesContainer = ({ chatId, me, query, onForward, isGroup }: Props) => {
  const messagesResult = useQuery({
    queryKey: ["chats", "messages", chatId],
    queryFn: () => messages({ id: chatId, params: { q: query } }),
  });

  return (
    <div className="no-scrollbar flex h-full flex-col-reverse gap-3 overflow-auto p-4">
      {messagesResult.isSuccess &&
        messagesResult.data.data.map(message => (
          <MessengerBubble
            chatId={chatId}
            showImage={isGroup}
            isSender={message.author.id === me.id}
            key={message.id}
            author={message.author}
            onForward={() =>
              onForward({
                text: message.text,
                link: message.link_preview?.image_url,
                media: message.media || [],
              })
            }
            message={{
              id: message.id,
              text: message.text,
              link_preview: message.link_preview,
              read: message.read,
              created_at: moment(message.created_at).format("DD.MM.YYYY, HH:mm"),
              media: message.media,
            }}
          />
        ))}
    </div>
  );
};

export default MessagesContainer;
