import { EyeIcon, PlayIcon } from "@heroicons/react/24/outline";
import { HeartIcon } from "@heroicons/react/24/solid";
import { useRef, useState } from "react";
import ReactPlayer from "react-player";

import ReactPlayerNavLink from "@src/components/elements/ReactPlayerNavLink";

type Props = {
  thumbnail: string;
  viewCount?: number;
  likeCount?: number;
  mediaId: string;
  mediaKey: string;
  small?: boolean;
  mediaUrl?: string;
};

const SoundsThumbnailItem = ({ thumbnail, mediaId, mediaUrl, mediaKey, ...props }: Props) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const ref = useRef<ReactPlayer>(null);

  return (
    <ReactPlayerNavLink
      to={`/watch/für_dich?featured=${mediaId}`}
      className={`group relative aspect-[4/5] bg-gray-300 lg:rounded-lg ${props.small && "aspect-auto h-[182px]"}`}
      playerRef={ref}
      onPause={() => setIsPlaying(false)}
      onResume={() => setIsPlaying(false)}
      onPlay={() => setIsPlaying(true)}
      onFailure={() => setIsPlaying(false)}
    >
      <div className="video-cover h-full object-cover lg:overflow-hidden lg:rounded-lg">
        <ReactPlayer
          url={`${mediaUrl}/playlist.m3u8`}
          height="100%"
          width="100%"
          controls={false}
          muted={false}
          playing={isPlaying}
          autoplay={true}
          loop
          responsive
          ref={ref}
        />
      </div>

      <PlayIcon className="absolute right-1 top-1 size-5" />
      <div className="absolute top-0 size-full group-hover:visible group-hover:bg-black/30 max-lg:bg-black/30 lg:invisible lg:rounded-lg">
        <div className="absolute top-1/2 w-full -translate-y-1/2 px-4">
          <div className="flex flex-row items-center justify-center gap-1 font-semibold text-white">
            <HeartIcon className="size-5" />
            <div>{props.likeCount}</div>
          </div>
          <div className="flex flex-row items-center justify-center gap-1 text-white">
            <EyeIcon className="flip size-5 -scale-x-100 text-white" />
            <div>{props.viewCount}</div>
          </div>
        </div>
      </div>
    </ReactPlayerNavLink>
  );
};

export default SoundsThumbnailItem;
