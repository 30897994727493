interface ListProps {
  items: string[];
  className?: string;
}

export const OrderedList = ({
  items,
  className = "list-inside list-decimal text-justify",
}: ListProps) => {
  return (
    <ol className={className}>
      {items.map((item, index) => (
        <li key={`${item}-${index}`}>{item}</li>
      ))}
    </ol>
  );
};

export const UnorderedList = ({
  items,
  className = "list-inside list-disc text-justify",
}: ListProps) => {
  return (
    <ul className={className}>
      {items.map((item, index) => (
        <li key={`${item}-${index}`}>{item}</li>
      ))}
    </ul>
  );
};
