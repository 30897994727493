import { useInfiniteQuery } from "@tanstack/react-query";
import { useState } from "react";

import feed from "@src/api/feed/feed";
import PostWidget from "@src/components/elements/PostWidget";
import nextPost from "@src/utils/nextPost";
import prevPost from "@src/utils/prevPost";

type Props = {
  searchQuery?: string;
};

const MyFeedContainer = ({ searchQuery }: Props) => {
  const [lightboxSelection, setLightboxSelection] = useState("");
  const queryParams = {
    q: searchQuery ?? null,
    user_id: null,
    liked_by: null,
  };

  const feedResult = useInfiniteQuery({
    queryKey: ["posts", "feed", "Following", queryParams],
    queryFn: ({ pageParam }) =>
      feed({ params: { ...queryParams, page: pageParam, filter: "followed_by_me" } }),
    initialPageParam: 1,
    getNextPageParam: lastPage => {
      const nextPage = lastPage.current_page + 1;
      return nextPage <= lastPage.total_pages ? nextPage : null;
    },
  });

  if (!feedResult.isSuccess) return <></>;

  return (
    <>
      {feedResult.data.pages.map((page, pageIndex) =>
        page.data.map((post, index: number) => (
          <div
            className="max-lg:odd:border-y max-lg:odd:border-y-gray-300 max-lg:odd:py-2"
            key={post.id}
          >
            <PostWidget
              postId={post.repost ? post.repost.id : post.id}
              post={post}
              key={post.repost ? post.repost.id : post.id}
              text={post.text}
              author={post.author}
              published_at={post.published_at}
              onSwitch={(id: string) => {
                setLightboxSelection(id);
              }}
              prev={prevPost({ index, pageIndex, postsResult: feedResult })}
              lightboxSelection={lightboxSelection}
              next={nextPost({ index, pageIndex, postsResult: feedResult })}
            />
          </div>
        )),
      )}
    </>
  );
};

export default MyFeedContainer;
